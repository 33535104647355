export default {
  setList (state, payload) {
    state.list = payload
  },

  newItem (state, payload) {
    state.list.push(payload)
  },

  updateItem (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
  },

  deleteItem (state, id) {
    const list = state.list.filter(item => item.id.toString() !== id.toString())
    state.list = []
    state.list = [...list]
  },

  setSelectedData (state, payload) {
    state.selectedData = payload
  },

  setSelectedGroups (state, payload) {
    state.selectedGroups = payload
  },

  setSelectedUnits (state, payload) {
    state.selectedUnits = payload
  },

  setGlobalSearch (state, payload) {
    state.globalSearch = payload
  },

  setSelectedTime (state, payload) {
    state.selectedTime = payload
  },

  setChecksObject (state, payload) {
    state.checksObject = Object.assign({}, payload)
  },

  setTasksObject (state, payload) {
    state.tasksObject = Object.assign({}, payload)
  },

  setChecksDialog (state, payload) {
    state.checksDialog = payload
  },

  setAssignDefectsDialog (state, payload) {
    state.assignDefectsDialog = payload
  },

  setTasksDialog (state, payload) {
    state.tasksDialog = payload
  },

  toggle () {}
}