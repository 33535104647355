
class UnitMarker extends window.google.maps.OverlayView {
  /**
   * 
   * @param {google} window.google
   * @param {position} window.google.maps.LatLng
   * @param {map} window.google.map
   * @param {args} Array
   */
  constructor ({ google, position, map, args, name }) {
    super()
    console.log('using unit marker')
    this._map = map
    this._position = position
    this._google = google
    this._name = name

    this._html = null

    this.args = args
    this.setMap(map)
    this.width = 100
    this.height = 18
  }

  onAdd () {
    /*
    <i aria-hidden="true" class="v-icon notranslate mdi mdi-car theme--light black--text"></i>
     */
    const speed = this.args?.position?.speed
    const direction = this.args?.position?.direction
    let color = 'primary--text'
    let mdiIcon = 'mdi-navigation'
    let deg = 0
    let iconSize = this.height - 6

    if (speed !== null) {
      color = speed === 0 ? 'blue' : 'green'
      mdiIcon = speed === 0 ? 'mdi-alpha-p' : 'mdi-navigation'
    }
    if (direction !== null) {
      deg = speed === 0 ? 0 : direction
      iconSize = speed === 0 ? this.height : this.height - 6
    }

    const icon = `
      <div
      class="v-avatar ma-auto ${color}"
      style="width: ${this.height}px; height: ${this.height}px; transform: rotate(${deg}deg); display: block">
        <i
        aria-hidden="true"
        class="v-icon notranslate mdi ${mdiIcon} theme--dark"
        style="font-size: ${iconSize}px"
        ></i>
      </div>
      <span class="v-chip v-chip theme--light v-size--small" style="background: rgba(115, 22, 14, 0.25);">
        <span class="v-chip__content">
        <strong
          class="mx-auto"
          style="display: block; color: black; text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;"
          >${this._name}</strong>
        </span>
      </span>
    `

    /*
      <span class="v-chip v-chip--no-color theme--light v-size--x-small">
        <span class="v-chip__content">
          <strong>${this._name}</strong>
        </span>
      </span>
    */

    const div = document.createElement('div')
    // div.className = 'marker'
    div.style.position = 'absolute'
    div.style.cursor = 'pointer'
    div.style.width = `${this.width}px`
    div.style.height = `${this.height}px`
    div.style.background = 'transparent'
    div.style.display = 'block'
    div.style.textAlign = 'center'

    div.innerHTML = icon

    this._html = div

    // Add the element to the "overlayLayer" pane.
    const panes = this.getPanes()
    panes.overlayLayer.appendChild(div)
  }

  draw () {
    const overlayProjection = this.getProjection()
    let pos = { x: 0, y: 0 }
    try {
      pos = overlayProjection.fromLatLngToDivPixel(this._position)
    } catch (error) {
      console.warning('Unit Marker Error', error.toString())
    }

    const div = this._html

    if (div !== null && pos) {
      div.style.left = `${pos.x - (this.width / 2)}px`
      div.style.top = `${pos.y - (this.height / 2)}px`
    }
  }

  setPosition (position) {
    this._position = new this._google.maps.LatLng(
      position.latitude,
      position.longitude
    )

    this.draw()
  }

  onRemove () {
    this._html.parentNode.removeChild(this._html)
    this._html = null
  }
}

UnitMarker.prototype.getDraggable = function () { return false }
UnitMarker.prototype.getPosition = function () {
  return this._position
}

module.exports = UnitMarker