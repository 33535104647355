<template>
  <v-row>
    <!-- Unit Label -->
    <v-col cols="12">
      <h3 style="font-size: 26px">{{ unit.name }}</h3>
    </v-col>
    <v-col cols="12">
      <v-list>
        <v-list-item two-line>
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              Last Check
            </v-list-item-title>
            <v-list-item-subtitle>
              <div
                class="d-flex align-center"
              >
                <v-icon :color="validateTelemetry() ? 'green' : 'red'">
                  {{ validateTelemetry() ? 'mdi-clock' : 'mdi-clock' }}
                </v-icon>
                <p class="ma-0 pl-2">
                  {{ $t('unitsSheet.unitInformation.lastMessage') }}: {{ getLastMessageDate() }}
                </p>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line>
          <v-list-item-content class="pa-0">
            <v-list-item-title>
              Location
            </v-list-item-title>
            <v-list-item-subtitle>
              <div
                class="d-flex align-center"
              >
                <v-icon :color="validateTelemetry() ? 'green' : 'red'">
                  {{ validateTelemetry() ? 'mdi-clock' : 'mdi-clock' }}
                </v-icon>
                <p class="ma-0 pl-2">
                  {{ address }}
                </p>
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <div v-if="unit.style !== 'TRAILER'" style="width: 100%">
      <img :src="unitImage()" style="width: 100%; max-height: 200px;">
      <!-- <gm-trailer-render
        id="summary-tab-render"
        key="summary-tab-render"
        reference="new-reference-test"
        height="20vh"
        :message="unit.telemetry"
        trailerId="Trailer-test"
      />
      -->
    </div>
    <div v-else style="width: 100%; margin-left: 5px">
      <gm-trailer-render
        id="tpms-summary-render"
        key="tpms-summary-render"
        height="80%"
        trailer-id="tpms-summary-render"
        :message="unit.tpmsTelemetry"
      />
    </div>
  </v-row>
</template>

<script>

import dayjs from 'dayjs'
import getAddress from 'GraphQL/queries/address/getAddress.gql'

import {
  TrailerRender
} from 'Components/utils/'
const utc = require('dayjs/plugin/utc')

export default {
  components: {
    // eslint-disable-next-line
    'gm-trailer-render': TrailerRender
  },
  props: {
    unit: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      componentKey: 0
    }
  },
  asyncComputed: {
    address: {
      get () {
        const positionObject = {}
        let geofenceIds = []
        if (this.unit?.telemetry?.address) {
          return this.unit.telemetry.address
        }
        if (this.unit.telemetry?.position?.latitude) {
          positionObject.latitude = this.unit.telemetry.position.latitude
          positionObject.longitude = this.unit.telemetry.position.longitude
          geofenceIds = this.unit.telemetry.geofencesIds
        } else {
          return 'the address was unable to retrieve'
        }
        const object = Object.assign({}, this.unit)
        // Get the address using apollo
        return this.$apollo.query({
          query: getAddress,
          variables: {
            apiToken: this.user.apiToken,
            position: positionObject,
            geofenceIds
          }
        }).then(response => {
          const { status, result } = response.data.getAddress
          switch (status) {
            case 'OK':
              object.telemetry.address = result
              this.$store.commit('units/updateUnit', object)
              this.$store.commit('units/updateUnitDialog', true)
              this.$store.dispatch('units/setUnitsEvent', this.$store.state)
              return result
            case 'ACCESSDENIED':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
              break
            case 'BADREQUEST':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
              break
            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
              break
          }
        }).catch(err => {
          console.error(err)
          this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
        }).finally(() => {
        })
      },
      default: 'Address'
    }
  },
  computed: {
    user () { return this.$store.state.session }
  },
  methods: {
    validateTelemetry () {
      return this.unit?.telemetry
    },
    getLastMessageDate () {
      // console.log(this.unit)
      if (this.unit?.telemetry) {
        return this.parseDateClean(this.unit.telemetry.receivedAt)
      } else {
        return 'Not Available'
      }
    },
    parseDateClean (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date * 1000).format('Do MMM YYYY - hh:mm a')
      }
    },
    unitImage () {
      // Validate if the unit style
      if (this.unit.style === 'TRAILER') {
        return this.$assets.unitTrailer
      } else if (this.unit.style === 'TRAILER_12') {
        return this.$assets.unitTrailer12
      } else if (this.unit.style === 'VAN') {
        return this.$assets.unitVan
      } else if (this.unit.style === 'CAR') {
        return this.$assets.unitCar
      } else if (this.unit.style === 'RIGID_TRUCK') {
        return this.$assets.unitRigidTruck
      } else if (this.unit.style === 'ARTIC_TRUCK') {
        return this.$assets.unitArticTruck
      } else {
        return this.$assets.unitDefault
      }
    }
  }
}
</script>