import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'
// graphql files
import list from 'GraphQL/queries/units/list.gql'
import reset from 'GraphQL/mutations/brakePerformanceReset/reset.gql'
import presenceInGeofences from 'GraphQL/queries/units/presenceInGeofences.gql'

export default {
  async getList ({ rootState, commit, dispatch }, payload = false) {
    if (payload) {
      await commit('setLoading', true, { root: true })
    }
    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.units

      switch (status) {
        case 'OK':
          commit('setList', result)
          dispatch('setValues')
          // for (const i in result) {
          //   if (result[i].telemetry?.position?.latitude) {
          //     dispatch('calculateAddress', result[i])
          //   }
          // }
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
    })
  },

  setValues ({ rootState, commit, dispatch }) {
    commit('setFaultUnits', { rootState: rootState })
    // commit('setHomeDepotsUnits', { rootState: rootState })
    // commit('setCustomerDepotList', { rootState: rootState })
    dispatch('getPresenceInGeofences')
    commit('setFlaggedVorList')
    commit('setUpcomingServicesList')
    commit('setUpcomingMOTList')
    commit('setOverdueServicesList')
    commit('setOverdueMOTList')
    commit('setEmptyRunningList')
    commit('setLoadedList')
    commit('setPoorBrakeList')
    commit('setAmberBrakeList')
    commit('dailyOdometers/setUnitsToOdometers', { rootState: rootState }, { root: true })
    commit('dtcs/setTimeList', { rootState: rootState }, { root: true })
    commit('events/setTimeList', { rootState: rootState }, { root: true })
    commit('emptyRunningTrips/setTimeList', { rootState: rootState }, { root: true })
    commit('stoodStatus/setTimeList', { rootState: rootState }, { root: true })
    commit('tpmsEvents/setUnitsToTheEvents', { rootState: rootState }, { root: true })
    commit('reports/setUnitsToList', { rootState: rootState, dispatch: dispatch }, { root: true })
    commit('setUnitsTpms')
    // Set all units to the map
    const units = []
    for (const i in rootState.units.list) {
      const unit = rootState.units.list[i]
      if (unit.telemetry?.position?.latitude && unit.telemetry?.position?.longitude) {
        units.push(unit)
      }
    }
    commit('mapMonitor/pushAllSelectedUnits', units, { root: true })
  },

  setUnitsEvent ({ rootState, commit }) { 
    commit('setUnitsEvent', { rootState: rootState })
  },

  async resetPerformanceRate ({ rootState, commit }, payload) {
    await commit('setLoading', true, { root: true })
    // Call mutation query
    apollo.mutate({
      mutation: reset,
      variables: {
        apiToken: rootState.session.apiToken,
        unitId: payload.unitId,
        password: payload.password
      }
    }).then(response => {
      // Clean this unit brake performance
      if (response.data.resetBrakePerformance.status === 'OK') {
        commit('toggleSnackbar', { message: i18n.t('units.performanceReset.success'), color: 'success' }, { root: true })
        commit('units/resetBrakePerformance', payload.unitId, { root: true })
        commit('units/resetBrakePerformanceList', payload.unitId, { root: true })
        commit('units/updateResetDialog', false, { root: true })
      } else {
        commit('toggleSnackbar', { message: i18n.t('errors.passwordDenied'), color: 'red darken-2' }, { root: true })
      }
    }).catch(err => {
      console.error(err)
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async getPresenceInGeofences ({ rootState, commit }) {
    await apollo.query({
      query: presenceInGeofences,
      variables: {
        apiToken: rootState.session.apiToken
      }
    }).then(response => {
      const { status, result } = response.data.presenceInGeofenceForUnits

      switch (status) {
        case 'OK':
          commit('setUnitsGeofencesIds', { rootState: rootState, payload: result })
          break
        case 'ACCESSDENIED':
          commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
    }).finally(() => {
    })
  }
}
