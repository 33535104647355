<template>
  <v-row v-dragscroll style="display: flex; overflow: hidden">
    <v-col v-for="(item, index) in sensors" :key="index" cols="4">
      <gm-sensor-pill :title="dictData[item.value].title" :color="dictData[item.value].color" :icon="dictData[item.value].icon" :value="dictData[item.value].value.toString()" :measure="dictData[item.value].measure" />
    </v-col>
  </v-row>
</template>

<script>

import {
  SensorPill
} from 'Components/utils/'

import { dragscroll } from 'vue-dragscroll'
import dayjs from 'dayjs'

export default {
  directives: {
    dragscroll
  },
  components: {
    'gm-sensor-pill': SensorPill
  },
  props: {
    unit: {
      type: Object,
      default: () => {}
    },
    tab: {
      type: Number,
      default: () => 0
    }
  },
  data () {
    return {
      arrayData: [],
      dictData: {}
    }
  },
  computed: {
    dialogValue () {
      return this.$store.state.units.unitDialog
    },
    dtcCodes () { return this.$store.state.dtcs.codeDictList },
    sensors () {
      if (this.unit.vehicleType) {
        return this.unit.vehicleType.sensors
      } else {
        return [
          {
            value: 'SPEED'
          },
          {
            value: 'ODOMETER'
          },
          {
            value: 'WEIGHT'
          },
          {
            value: 'DTC'
          },
          {
            value: 'NEXT_SERVICE'
          },
          {
            value: 'BRAKE_PERFORMANCE'
          }
        ]
      }
    }
  },
  watch: {
    unit (_) {
      if (this.unit) {
        this.calculateSensorValues()
      }
    },
    tab (_) {
      if (this.unit) {
        this.calculateSensorValues()
      }
    },
    dialogValue (val) {
      if (val) {
        this.calculateSensorValues()
      }
    }
  },

  created () {
    this.dictData = {
      SPEED: {
        title: 'Speed',
        value: this.unit?.telemetry?.position?.speed ? this.unit?.telemetry?.position?.speed : this.$t('units.message.collectingData'),
        measure: 'km/h',
        color: 'grey',
        icon: 'mdi-speedometer'
      },
      ODOMETER: {
        title: 'Mileage',
        value: this.unit?.telemetry?.odometer ? this.unit.telemetry.odometer.toFixed(2) : this.$t('units.message.collectingData'),
        measure: 'km',
        color: 'grey',
        icon: 'mdi-gauge'
      },
      WEIGHT: {
        title: 'Weight',
        value: this.unit?.telemetry?.axeLoadSum ? this.unit.telemetry.axeLoadSum : this.$t('units.message.collectingData'),
        measure: 'kg',
        color: 'grey',
        icon: 'mdi-weight'
      },
      DTC: {
        title: 'Current DTC',
        value: this.getDtcCode(),
        measure: '',
        color: this.getDtcCodeColor(),
        icon: 'mdi-hazard-lights'
      },
      NEXT_SERVICE: {
        title: 'Next Service',
        value: this.getNextService(),
        measure: '',
        color: 'grey',
        icon: 'mdi-calendar'
      },
      BRAKE_PERFORMANCE: {
        title: 'Brake performance',
        value: this.unit?.brakePerformanceRate?.rate ? this.unit?.brakePerformanceRate?.rate.toFixed(2) : this.$t('units.message.collectingData'),
        measure: '%',
        color: this.getBrakePerformanceColor(),
        icon: 'mdi-car-brake-alert'
      },
      DRIVER: {
        title: 'Driver',
        value: this.gerDriverName(),
        measure: '',
        color: 'grey',
        icon: 'mdi-account'
      },
      FUEL_LEVEL: {
        title: 'Fuel level',
        value: this.getFuelLever(),
        measure: '%',
        color: 'grey',
        icon: 'mdi-gas-station'
      },
      STATE_DURATION: {
        title: 'State duration',
        value: this.getStateDuration(),
        measure: 'h',
        color: 'grey',
        icon: 'mdi-timer'
      }
    }
    this.arrayData = [{
      title: 'Speed',
      value: this.unit?.telemetry?.position?.speed ? this.unit?.telemetry?.position?.speed : this.$t('units.message.collectingData'),
      measure: 'km/h',
      color: 'grey',
      icon: 'mdi-speedometer'
    },
    {
      title: 'Mileage',
      value: this.unit?.telemetry?.odometer ? this.unit.telemetry.odometer.toFixed(2) : this.$t('units.message.collectingData'),
      measure: 'km',
      color: 'grey',
      icon: 'mdi-gauge'
    },
    {
      title: 'Weight',
      value: this.unit?.telemetry?.axeLoadSum ? this.unit.telemetry.axeLoadSum : this.$t('units.message.collectingData'),
      measure: 'kg',
      color: 'grey',
      icon: 'mdi-weight'
    },
    // {
    //   title: 'DTC alerts',
    //   value: '0',
    //   measure: 'DTCs',
    //   icon: 'mdi-hazard-lights'
    // },
    {
      title: 'Current DTC',
      value: this.getDtcCode(),
      measure: '',
      color: this.getDtcCodeColor(),
      icon: 'mdi-hazard-lights'
    },
    {
      title: 'Next Service',
      value: this.getNextService(),
      measure: '',
      color: 'grey',
      icon: 'mdi-calendar'
    },
    // {
    //   title: 'Alerts',
    //   value: '0',
    //   measure: '',
    //   icon: 'mdi-alert-circle-outline'
    // },
    {
      title: 'Brake performance',
      value: this.unit?.brakePerformanceRate?.rate ? this.unit?.brakePerformanceRate?.rate.toFixed(2) : this.$t('units.message.collectingData'),
      measure: '%',
      color: this.getBrakePerformanceColor(),
      icon: 'mdi-car-brake-alert'
    }]
  },
  methods: {
    getBrakePerformanceColor () {
      if (this.unit?.brakePerformanceRate?.rate) {
        if (this.unit.brakePerformanceRate.rate < 45) {
          return 'red'
        } else if (this.unit.brakePerformanceRate.rate < 47) {
          return 'amber'
        } else {
          return 'green'
        }
      } else {
        return 'grey'
      }
    },
    getNextService () {
      // Validate if the profile has the next service value and the last service value
      if (this.unit.trailerProfile.nextServiceDate) {
        return dayjs(this.unit.trailerProfile.nextServiceDate).format('Do MMMM YYYY')
      } else {
        return this.$t('units.message.notSet')
      }
    },
    calculateSensorValues () {
      this.arrayData[0].value = this.unit?.telemetry?.position?.speed ? this.unit?.telemetry?.position?.speed : this.$t('units.message.collectingData')
      this.arrayData[1].value = this.unit?.telemetry?.odometer ? this.unit.telemetry.odometer.toFixed(2) : this.$t('units.message.collectingData')
      this.arrayData[2].value = this.unit?.telemetry?.axeLoadSum ? this.unit.telemetry.axeLoadSum : this.$t('units.message.collectingData')
      this.arrayData[3].value = this.getDtcCode()
      this.arrayData[3].color = this.getDtcCodeColor()
      this.arrayData[5].value = this.unit?.brakePerformanceRate?.rate ? this.unit?.brakePerformanceRate?.rate.toFixed(2) : this.$t('units.message.collectingData')
      this.dictData.SPEED.value = this.unit?.telemetry?.position?.speed ? this.unit?.telemetry?.position?.speed : this.$t('units.message.collectingData')
      this.dictData.ODOMETER.value = this.unit?.telemetry?.odometer ? this.unit.telemetry.odometer.toFixed(2) : this.$t('units.message.collectingData')
      this.dictData.WEIGHT.value = this.unit?.telemetry?.axeLoadSum ? this.unit.telemetry.axeLoadSum : this.$t('units.message.collectingData')
      this.dictData.DTC.value = this.getDtcCode()
      this.dictData.DTC.color = this.getDtcCodeColor()
      this.dictData.BRAKE_PERFORMANCE.value = this.unit?.brakePerformanceRate?.rate ? this.unit?.brakePerformanceRate?.rate.toFixed(2) : this.$t('units.message.collectingData')
      this.dictData.NEXT_SERVICE.value = this.getNextService()
      this.dictData.DRIVER.value = this.gerDriverName()
      this.dictData.FUEL_LEVEL.value = this.getFuelLever()
      this.dictData.STATE_DURATION.value = this.getStateDuration()
    },

    getDtcCode () {
      if (this.unit.dtcEvent && this.unit.telemetry.dtcEvent) {
        const dtcCode = this.dtcCodes[this.unit.dtcEvent.dtcCodeId]
        return dtcCode.shortName
      } else {
        return this.$t('units.message.noDtc')
      }
    },
    getDtcCodeColor () {
      if (this.unit.dtcEvent && this.unit.telemetry.dtcEvent) {
        if (this.unit.dtcEvent.dtcAmberState === 16 || this.unit.dtcEvent.dtcAmberState === 32) {
          return 'amber'
        } else if (this.unit.dtcEvent.dtcRedState === 4 || this.unit.dtcEvent.dtcRedState === 32) {
          return 'red'
        } else {
          return 'grey'
        }
      } else {
        return 'green'
      }
    },
    gerDriverName () {
      if (this.unit.driver) {
        return this.unit.driver.firstName + ' ' + this.unit.driver.lastName
      } else {
        return this.$t('units.message.noDriver')
      }
    },
    getFuelLever () {
      if (this.unit.telemetry.fuelLevel) {
        return this.unit.telemetry.fuelLevel
      } else {
        return this.$t('units.message.collectingData')
      }
    },
    getStateDuration () {
      if (this.unit.telemetry.stateDuration) {
        return this.unit.telemetry.stateDuration
      } else {
        return this.$t('units.message.collectingData')
      }
    }
  }
}
</script>