import Vue from 'vue'
import DatetimePicker from '@goldenm/vuetify-datetime-picker'
import list from 'GraphQL/queries/brakeEvents/list.gql'
import tpmsMessageList from 'GraphQL/queries/tpmsMessages/list.gql'
import tpmsEvents from 'GraphQL/queries/tpms/tpmsEvents.gql'
import changeTpmsEvents from 'GraphQL/queries/tpms/changeTpmsEvents.gql'
import VueDragscroll from 'vue-dragscroll'
import App from './App.vue'
import { sync } from 'vuex-router-sync'
import { i18n, vuetify, assets, router, store, apollo, orm, axios, googlemaps } from '@/plugins'
import { Datetime } from 'vue-datetime'
import AsyncComputed from 'vue-async-computed'
import getApp from 'GraphQL/queries/apps/get.gql'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'

// Charts library
import VueApexCharts from 'vue-apexcharts'

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import '@/assets/application.scss'

Vue.use(VueApexCharts)
Vue.use(DatetimePicker)
Vue.component('Apexchart', VueApexCharts)

Vue.use(Datetime)
Vue.component('Datetime', Datetime)

Vue.use(assets)
Vue.use(orm)
Vue.use(axios)
Vue.use(googlemaps)
Vue.use(VueDragscroll)
Vue.use(AsyncComputed)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

// Vue.use(VueCodemirror)

sync(store, router)

new Vue({
  i18n,
  vuetify,
  router,
  store,
  apolloProvider: apollo,

  computed: {
    messages () {
      return this.$i18n.messages
    },
    routeParams () {
      return window.location.search
    }
  },

  watch: {
    $route (newVal) {
      document.title = `${this.$i18n.t(`pages.${newVal.name}`)} | ${this.$i18n.t('app.title')}`
    },

    messages () {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.$i18n.t('app.title')}`
    }
  },

  beforeCreate () {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'setAvailableLanguages') {
        const locale = localStorage.getItem('operative.current.locale.code')
        if (locale && locale !== 'undefined') {
          this.$i18n.locale = locale
        } else {
          const lang = navigator.language || navigator.userLanguage
          const check = mutation.payload.filter(item => item.code === lang)
          if (check.length === 0) {
            this.$i18n.locale = 'en-GB'
          } else {
            this.$i18n.locale = 'en-GB'
          }
        }
        this.$store.commit('setLanguage', this.$i18n.locale)
      }
    })

    this.$store.dispatch('languages/fetchI18n')
    window.addEventListener('resize', () => {
      if (this.$store.state.width.full !== window.innerWidth) {
        this.$store.commit('setWidth', window.innerWidth)
      }
    })
    this.$apollo.query({
      query: getApp,
      variables: {
        host: window.location.hostname
      },
      fetchPolicy: 'no-cache'
    }).then(async (response) => {
      const { status, result } = response.data.whiteLabel
      let link
      let primaryColor
      let secondaryColor
      switch (status) {
        case 'OK':
          console.log('App', result)
          primaryColor = `#${result.primaryColor}`
          secondaryColor = `#${result.secondaryColor}`
          console.log('primaryColor', primaryColor)
          console.log('secondaryColor', secondaryColor)
          this.$vuetify.theme.themes.light.primary = primaryColor
          this.$vuetify.theme.themes.light.secondary = secondaryColor
          this.$vuetify.theme.themes.dark.primary = primaryColor
          this.$vuetify.theme.themes.dark.secondary = secondaryColor
          this.$store.commit('general/setApp', {
            ...result,
            primaryColor,
            secondaryColor
          })
          link = document.querySelector("link[rel~='icon']")
          if (!link) {
            link = document.createElement('link')
            link.rel = 'icon'
            document.getElementsByTagName('head')[0].appendChild(link)
          }
          link.href = result.favicon
          break

        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar')
          break

        case 'NOTFOUND':
          
          this.$store.commit('setLoading', false)
          break
      }
    }).catch(() => {
      this.$store.commit('toggleSnackbar')
    })

    const dark = localStorage.getItem('operative.dark.mode')
    if (dark !== undefined) {
      this.$store.commit('setDark', dark === 'true')
      this.$vuetify.theme.dark = dark === 'true'
    }
  },

  created () {
    const apiToken = localStorage.getItem('token.token')

    this.getSession()

    if (apiToken === undefined) {
      // this.$router.push('/Login')

      this.$store.commit('setLoading', false)
    }
  },

  mounted () {
    document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.$i18n.t('app.title')}`
    // this.$store.dispatch('getCountries')
    // this.$store.dispatch('getMeasureUnits')
  },

  beforeDestroy () {
    window.removeEventListener('resize')
  },

  methods: {
    getSession () {
      const apiToken = localStorage.getItem('token.token')
      if (apiToken !== undefined) {
        this.$store.dispatch('session/loadEntity')
      }
    }
  },

  render: h => h(App)
}).$mount('#app')

// 4. add an instance method
Vue.prototype.$showUnitDialog = async function (unit) {
  const object = Object.assign({}, unit)
  const user = this.$store.state.session
  await this.$apollo.query({
    query: list,
    variables: {
      apiToken: user.apiToken,
      unitId: object.id
    }
  }).then(response => {
    const { status, result } = response.data.brakeEvents
    const resultRates = response.data.brakePerformanceRates.result
    const resultBrakePerformanceRates = response.data.brakePerformanceSlope.result
    const resultBrakePerformanceResets = response.data.brakePerformanceResets.result
    const resultTpms = response.data.lastTpmsId.result
    const resultVehicleType = response.data.vehicleTypeByUnitId.result
    switch (status) {
      case 'OK':
        object.events = result
        object.brakePerformanceRates = resultRates
        object.brakePerformanceSlope = resultBrakePerformanceRates
        object.brakePerformanceResets = resultBrakePerformanceResets
        object.lastTpmsId = resultTpms
        object.vehicleType = resultVehicleType
        break
      case 'ACCESSDENIED':
        this.$store.commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
        break
      case 'BADREQUEST':
        this.$store.commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
        break
      case 'UNPROCESSABLE':
        this.$store.commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
        break
      case 'INTERNALERROR':
        this.$store.commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
        break
    }
  }).catch(err => {
    console.error(err)
    this.$store.commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
  }).finally(() => {
    
  })
  console.log('showUnitDialog has been called')
  this.$store.commit('units/updateUnit', object)
  this.$store.commit('units/updateUnitDialog', true)
  this.$store.dispatch('units/setUnitsEvent', this.$store.state)
  // Get the tpms data
  await this.$apollo.query({
    query: tpmsMessageList,
    variables: {
      apiToken: user.apiToken,
      unitId: object.id
    }
  }).then(response => {
    const { status, result } = response.data.tpmsMessages
    switch (status) {
      case 'OK':
        this.$store.commit('units/updateUnitTpmsMessages', result)
        break
      case 'ACCESSDENIED':
        this.$store.commit('toggleSnackbar', { message: i18n.t('errors.accessDenied'), color: 'red darken-2' }, { root: true })
        break
      case 'BADREQUEST':
        this.$store.commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
        break
      case 'UNPROCESSABLE':
        this.$store.commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'orange darken-2' }, { root: true })
        break
      case 'INTERNALERROR':
        this.$store.commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
        break
    }
  }).catch(err => {
    console.error(err)
    this.$store.commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
  }).finally(() => {
    
  })
  // Get the Tpms events
  await this.$apollo.query({
    query: tpmsEvents,
    variables: {
      apiToken: user.apiToken,
      unitId: object.id,
      rangeType: 'last500'
    }
  }).then(response => {
    const { status, result } = response.data.tpmsEvents
    switch (status) {
      case 'OK':
        this.$store.commit('units/updateUnitTpmsEvents', result)
        break
      case 'ACCESSDENIED':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
        break
      case 'BADREQUEST':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
        break
      case 'UNPROCESSABLE':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
        break
      case 'INTERNALERROR':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
        break
    }
  }).catch(err => {
    console.error(err)
    this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
  }).finally(() => {
    
  })

  // Get using graphql the data for the dialog
  await this.$apollo.query({
    query: changeTpmsEvents,
    variables: {
      apiToken: user.apiToken,
      unitId: object.id,
      rangeType: 'last500'
    }
  }).then(response => {
    const { status, result } = response.data.changeTpmsEvents
    switch (status) {
      case 'OK':
        this.$store.commit('units/updateUnitChangeTpmsEvents', result)
        break
      case 'ACCESSDENIED':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
        break
      case 'BADREQUEST':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
        break
      case 'UNPROCESSABLE':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
        break
      case 'INTERNALERROR':
        this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
        break
    }
  }).catch(err => {
    console.error(err)
    this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
  }).finally(() => {
    this.$store.commit('setLoading', false, { root: true })
  })
}
