<template>
  <gm-expandable-list
    expanded-height="300px"
    :mandatory="mandatory"
    :title="$t('dashboard.vorEvents')"
    :value="historicVorList.length"
    :data="historicVorList"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #leadingTitle>
      <v-icon
        class="ml-3"
        color="yellow darken-2"
      >
        mdi-alert
      </v-icon>
    </template>
    <template #headerTrail>
      <div
        style="z-index: 10;"
        class="d-flex align-center"
      >
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-swap-vertical-bold</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="(item, index) in sortOptions"
                :key="index"
                @click="sortValue = item.value"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>

        <v-avatar
          size="28"
          color="accent"
        >
          <b>{{ historicVorList.length }}</b>
        </v-avatar>
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-historic-list-item
        :index="index"
        :value="item.finalOdometer - item.initOdometer"
        icon="mdi-truck"
        :icon-label="item.unit.name"
        :date="item.endAt * 1000"
        :is-amber="true"
        @click.native="showDialog(item.unit)"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import dayjs from 'dayjs'

import {
  ExpandableList,
  HistoricVorItem
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-historic-list-item': HistoricVorItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    },
    search: {
      type: String,
      default: () => ''
    },
    // sortValue: {
    //   type: String,
    //   default: () => ''
    // },
    dailyValue: {
      type: Number,
      default: () => 0
    },
    dailyOptions: {
      type: Array,
      default: () => [
      ]
    }
  },
  data () {
    return {
      sortValue: 'n2o'
    }
  },
  computed: {
    units () { return this.$store.state.units.list },
    unitsDict () { return this.$store.state.units.dictList },
    historicVor () { return this.$store.state.historicVor.eventList },
    historicVorList () {
      console.log(this.historicVor)
      // const start = this.getStart()
      // const end = this.getEnd()
      let result = this.historicVor.map(event => {
        return {
          ...event,
          unit: this.unitsDict[event.unitId]
        }
      })

      // filter by date
      result = this.filterByDate(result)

      // sort by date or rate
      if (this.sortValue === 'n2o') {
        result = this.sortByDate(result, 2)
      } else if (this.sortValue === 'o2n') {
        result = this.sortByDate(result)
      } else if (this.sortValue === 'h2l') {
        result = this.sortByKilometers(result, 2)
      } else if (this.sortValue === 'l2h') {
        result = this.sortByKilometers(result)
      }

      // filter by search
      if (this.search) {
        result = result
          .filter(el =>
            el.unit &&
            el.unit.name &&
            el.unit.name.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
    },
    sortOptions () {
      return [
        {
          text: 'Newest to oldest',
          value: 'n2o'
        },
        {
          text: 'Oldest to newest',
          value: 'o2n'
        },
        {
          text: 'Hight to low',
          value: 'h2l'
        },
        {
          text: 'Low to hight',
          value: 'l2h'
        }
      ]
    }
  },
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (unit) {
      this.$showUnitDialog(unit)
    },
    getKilometer (item) {
      return item.finalOdometer - item.initOdometer
    },
    sortByDate (array, val = 0) {
      array.sort(function (a, b) {
        if (a?.endAt < b?.endAt) {
          return -1 + val
        }
        if (a?.endAt > b?.endAt) {
          return 1 - val
        }
        return 0
      })
      return array
    },
    sortByKilometers (array, val = 0) {
      const self = this
      array.sort(function (a, b) {
        if (self.getKilometer(a) < self.getKilometer(b)) {
          return -1 + val
        }
        if (self.getKilometer(a) > self.getKilometer(b)) {
          return 1 - val
        }
        return 0
      })
      return array
    },
    filterByDate (array) {
      const result = [...array]
      const dailyValue = this.dailyOptions[this.dailyValue]
      const today = dayjs()

      switch (dailyValue) {
        case 'today':
          return result
            .filter(el => dayjs(el?.endAt * 1000)
              .isSame(today, 'day'))

        case 'yesterday':
          return result
            .filter(el => dayjs(el?.endAt * 1000)
              .isSame(today.subtract(1, 'day'), 'day'))

        case 'thisWeek':
          return result
            .filter(el => dayjs(el?.endAt * 1000)
              .isSame(today, 'week'))

        case 'lastWeek':
          return result
            .filter(el => dayjs(el?.endAt * 1000)
              .isSame(today.subtract(1, 'week'), 'week'))

        default:
          return result
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>