/*
  General actions
*/
// graphql files
import { apollo } from '@/plugins/apollo'
import timezones from 'GraphQL/queries/utils/timezones.gql'
import assetsKinds from 'GraphQL/queries/utils/assetKinds.gql'

export default {
  getTimezones ({ commit }) {
    apollo.query({
      query: timezones
    }).then((response) => {
      const { status, result } = response.data.timezones

      switch (status) {
        case 'OK':
          commit('setTimezones', result)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.log(error)
      commit('toggleSnackbar', undefined, { root: true })
    })
  },
  getAssetKinds ({ commit }) {
    apollo.query({
      query: assetsKinds
    }).then((response) => {
      const { status, result } = response.data.assetKinds

      switch (status) {
        case 'OK':
          commit('setAssetKinds', result)
          break
        default:
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      console.log(error)
      commit('toggleSnackbar', undefined, { root: true })
    })
  }
}