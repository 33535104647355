<template>
  <v-dialog
    :value="dialog"
    :max-width="width.dialog"
    scrollable
    persistent
    transition="dialog-transition"
  >
    <v-card height="80vh">
      <v-row style="width: 100%; margin: 0">
        <v-col
          cols="12"
          class="pa-0 d-flex flex-column better-scrollbar"
          style="height: 80vh; overflow-y: auto"
        >
          <v-card-title>
            {{ title }}
            <v-spacer />
            <v-btn icon text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider class="mx-4 my-2" />

          <v-card-text class="better-scrollbar pa-6">
            <v-row no-gutters class="">
              <v-col cols="12" class="pa-2">
                <!-- autocomplete for depot -->
                <v-autocomplete
                  v-model="object.depotId"
                  :items="depots"
                  item-text="name"
                  item-value="id"
                  :label="$t('geofences.assignHomeDepot')"
                  outlined
                  dense
                  hide-details
                  :clearable="!hasDepotId"
                  :readonly="hasDepotId"
                  :disabled="!isEditting"
                />
              </v-col>
              <v-col cols="12" class="pa-2">
                <hr>
              </v-col>
              <!-- Assign section title -->
              <v-col cols="12" class="pa-2">
                <v-subheader>{{ $t('geofences.assignOptions') }}</v-subheader>
              </v-col>
              <!-- Assign type -->
              <!-- Using Radio -->
              <v-col cols="12" class="pa-2" style="display: flex; justify-content: center;">
                <v-radio-group
                  v-model="assignationType"
                  row
                  :disabled="!isEditting"
                >
                  <v-radio
                    v-for="option in typeOptions"
                    :key="option.value"
                    :label="option.text"
                    :value="option.value"
                  />
                </v-radio-group>
              </v-col>
              <!-- Units Selector -->
              <v-col v-if="assignationType === 'units'" cols="12" class="pa-2">
                <!-- Dual list for units -->
                <v-duallist
                  v-model="object.unitsIds"
                  :data="unitsItems"
                  :disabled="!isEditting"
                  :show-title="false"
                  :title="$t('units.presence.select')"
                  :select-all-label="$t('actions.selectAll')"
                  :deselect-all-label="$t('actions.deselectAll')"
                  :data-list-title="$t('units.presence')"
                  :selected-list-title="$t('units.presence.selected')"
                  identifier-param="name"
                  search-param="name"
                  :scroll-height="135"
                  :item-height="40"
                  :search-selected-label="$t('units.presence.searchData')"
                  :search-data-label="$t('units.presence.searchData')"
                >
                  <template #item-render="{ item }">
                    {{ item.name }}
                  </template>
                </v-duallist>
              </v-col>
              <!-- Groups selector -->
              <v-col v-if="assignationType === 'group'" cols="12" class="pa-2">
                <!-- Autocomplete for groups -->
                <v-autocomplete
                  v-model="object.groupId"
                  :items="groups"
                  item-text="name"
                  item-value="id"
                  :label="$t('groups.title.group')"
                  outlined
                  dense
                  hide-details
                  clearable
                  :disabled="!isEditting"
                />``
              </v-col>
            </v-row>
          </v-card-text>

          <v-spacer />
          <v-card-actions class="d-flex justify-space-between flex-row-reverse">
            <v-btn
              v-if="isEditting"
              color="green darken-2 white--text"
              :disabled="isLoading || validate"
              @click="save"
            >
              <v-icon>mdi-check-circle</v-icon>
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { orm } from '@/mixins'
import assignHomeDepotToUntis from 'GraphQL/mutations/geofences/assignHomeDepotToUnits.gql'
import { DualList } from 'Components/utils'
 
import { mapState } from 'vuex'
// import { Message } from '@/components/layo'

export default {
  components: {
    'v-duallist': DualList
  },
  mixins: [orm],
  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    readonly: {
      type: Boolean,
      default: () => false
    },

    showDialog: {
      type: Boolean,
      default: () => false
    },

    depotId: {
      type: String,
      default: () => ''
    }
  },

  data () {
    return {
      assignationType: 'units',
      defaultObject: {
        unitsIds: [],
        groupId: '',
        depotId: ''
      },
      object: {
        unitsIds: [],
        groupId: '',
        depotId: ''
      },
      errors: {},
      loading: false
    }
  },

  computed: {
    ...mapState(['width', 'isLoading', 'isDark']),
    user () { return this.$store.state.session },
    isEditting: {
      get () {
        return !this.readonly
      },
      set (value) {
        this.$emit('set-readonly', !value)
      }
    },
    // Geofences with category Depot
    depots () {
      return this.$store.state.geofences.list.filter(geofence => geofence.category === 'DEPOT')
    },
    // Groups list
    groups () {
      return this.$store.state.groups.list
    },
    unitsItems () {
      return this.$store.state.units.list
    },
    validate () {
      if (!this.object.depotId) {
        return true
      }
      if (this.assignationType === 'units' && this.object.unitsIds.length === 0) {
        return true
      }
      if (this.assignationType === 'group' && !this.object.groupId) {
        return true
      }
      return false
    },
    typeOptions () {
      return [
        { text: this.$t('geofences.assignOptions.units'), value: 'units' },
        { text: this.$t('geofences.assignOptions.groups'), value: 'group' },
        { text: this.$t('geofences.assignOptions.all'), value: 'all' }
      ]
    },
    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    title () {
      return this.$i18n.t('groups.title.assignDepotToUnits')
    },

    hasDepotId () {
      return this.depotId !== '' && this.depotId !== null
    }
  },

  watch: {
    // dialog (value) {
    //   if (value) {
    //     window.particlesJS.load('sidebarParticles', '/particles.sidebar.json', () => {
    //       console.log('[ParticlesJS] Loaded')
    //     })
    //   }
    // }
    dialog (value) {
      this.object = Object.assign(this.object, this.defaultObject)

      if (value) {
        if (this.depotId) {
          this.object.depotId = this.depotId
        }
      }
    }
  },

  beforeDestroy () {
    this.resetErrors()
  },

  created () {
    this.resetErrors()
  },

  methods: {
    async save () {
      this.resetErrors()
      this.loading = true
      // Prepare units ids
      let unitsIds = []
      if (this.assignationType === 'units') {
        unitsIds = this.object.unitsIds.map(unit => unit.id)
      }
      if (this.assignationType === 'group') {
        // Get units from group
        const group = this.groups.find(group => group.id === this.object.groupId)
        unitsIds = group.units
      }
      if (this.assignationType === 'all') {
        // Get all units
        unitsIds = this.unitsItems.map(unit => unit.id)
      }
      const variables = {
        apiToken: this.user.apiToken,
        homeDepotId: this.object.depotId,
        unitIds: unitsIds
      }
      this.$apollo
        .mutate({
          mutation: assignHomeDepotToUntis,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors } = response.data.assignHomeDepotToUnits
          switch (status) {
            case 'OK':        
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('geofences.success.assignHomeDepotUnits', {
                  count: unitsIds.length
                }),
                color: 'success'
              })
              this.$store.dispatch('units/getList', false)
              break
            case 'BADREQUEST':
              this.errors = this.parseErrors(errors)
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
              break
            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.not_found'), color: 'warning' }, { root: true })
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
