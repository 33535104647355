const assets = {}

assets.install = (Vue) => {
  Vue.prototype.$assets = {
    logo: {
      normal: require('@/assets/logo.svg'),
      white: require('@/assets/logo-white.svg')
    },
    hirecoAppLogo: {
      normal: require('@/assets/hireco-logo.svg'),
      white: require('@/assets/hireco-logo.svg')
    },
    favicon: {
      normal: require('@/assets/logo-desktop.svg'),
      white: require('@/assets/logo-desktop.svg')
    },
    robot: {
      normal: 'https://layrz-production.s3-us-west-2.amazonaws.com/resources/roberto-robato/normal.svg',
      white: 'https://layrz-production.s3-us-west-2.amazonaws.com/resources/roberto-robato/white.svg'
    },
    // background: 'https://layrz-production.s3-us-west-2.amazonaws.com/resources/background/primary.png',
    sidebar: {
      // background: 'https://layrz-production.s3-us-west-2.amazonaws.com/resources/background/fav-primary.png'
    },
    trucks: {
      1: require('@/assets/truck1.png'),
      2: require('@/assets/truck2.png'),
      3: require('@/assets/truck3.png')
    },

    carMarker: require('@/assets/car.svg'),
    mapMarker: require('@/assets/map-marker.svg'),

    vinnieLogo: require('@/assets/vinnieIcon.svg'),
    vanguarderTrailer: require('@/assets/vanguarder-trailer.svg'),

    // vanguarderTrailer2: require('@/assets/vanguarder-trailer-2.svg'),
    // vanguarderTrailer3: require('@/assets/vanguarder-trailer-3.svg'),
    // vanguarderTrailer4: require('@/assets/vanguarder-trailer-4.svg'),

    // vanguarderTrailerInline: require('@/assets/vanguarder-trailer_inline.svg'),
    // vanguarderTrailerInline2: require('@/assets/vanguarder-trailer_inline2.svg'),

    loginBackground: require('@/assets/background.png'),

    tire: require('@/assets/TireVanguarder.svg'),
    unitTrailer: require('@/assets/vehicle_styles/trailer.svg'),
    unitArticTruck: require('@/assets/vehicle_styles/artic_truck.svg'),
    unitCar: require('@/assets/vehicle_styles/car.svg'),
    unitVan: require('@/assets/vehicle_styles/van.svg'),
    unitRigidTruck: require('@/assets/vehicle_styles/rigid_truck.svg'),
    unitTrailer12: require('@/assets/vehicle_styles/trailer.svg')
  }
}

export default assets
