<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      :nudge-width="200"
    >
      <template #activator="{ on, attrs }">
        <v-card
          style="min-width: 10%;"
          class="ma-2 tyre_pill__card"
          :class="highlight ? ' highlight' : ''"
          v-bind="attrs"
          v-on="on"
        >
          <v-card-text style="display: flex; justify-content: space-evenly; align-items: center;">
            <div style="display: flex; flex-direction: column; justify-content: center;">
              <div style="transform: rotate(90deg);">
                <svg xmlns="http://www.w3.org/2000/svg" width="31.751" height="69.403" viewBox="0 0 31.751 69.403">
                  <g id="Grupo_1363" data-name="Grupo 1363" transform="translate(0 69.403) rotate(-90)">
                    <rect id="Rectángulo_2366" data-name="Rectángulo 2366" width="69.403" height="31.751" rx="7.02" 
                          :fill="getTyreColor()"
                    />
                    <line id="Línea_138" data-name="Línea 138" x2="69.403" transform="translate(0 25.883)" fill="none" 
                          stroke="#4a4a4a" stroke-miterlimit="10" stroke-width="0.5"
                    />
                    <line id="Línea_139" data-name="Línea 139" x2="69.403" transform="translate(0 19.299)" fill="none" 
                          stroke="#4a4a4a" stroke-miterlimit="10" stroke-width="0.5" 
                    />
                    <line id="Línea_140" data-name="Línea 140" x2="69.403" transform="translate(0 12.714)" fill="none" 
                          stroke="#4a4a4a" stroke-miterlimit="10" stroke-width="0.5" 
                    />
                    <line id="Línea_141" data-name="Línea 141" x2="69.403" transform="translate(0 6.13)" fill="none" 
                          stroke="#4a4a4a" stroke-miterlimit="10" stroke-width="0.5" 
                    />
                  </g>
                </svg>
              </div>
              <p class="text">{{ tyre.name }}</p>
            </div>
            <div style="display: flex; align-items: center; justify-content: center; flex-direction: row;">
              <v-icon>mdi-thermometer</v-icon>
              <p class="text">{{ getFormatTemperature() }}</p>
            </div>
            <div style="display: flex; align-items: center; justify-content: center; flex-direction: row;">
              <v-icon>mdi-waves</v-icon>
              <p class="text">{{ getFormatPressure() }}</p>
            </div>
            <div style="display: flex; align-items: center; justify-content: center; flex-direction: row;">
              <v-icon>mdi-map-marker-outline</v-icon>
              <p class="text">Coming soon!</p>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <v-card
        class="info_menu__card pa-4"
      >
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <p class="info--title subtitle-2 mb-1">Tire position</p>
              <span class="info--value font-weight-black">{{ tyre.name }}</span>
            </v-col>
            <v-col cols="6">
              <p class="info--title subtitle-2 mb-1">Sensor ID</p>
              <span class="info--value font-weight-black">{{ sensorId }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <p class="info--title subtitle-2 mb-1">Placard Pressure</p>
              <span class="info--value font-weight-black">{{ getPsi() }}</span>
            </v-col>
            <v-col cols="6">
              <p class="info--title subtitle-2 mb-1">date installed</p>
              <span class="info--value font-weight-black">{{ getDate() }}</span>
            </v-col>
          </v-row>
          <v-row>
            <!-- <v-col cols="6">
              <p class="info--title subtitle-2 mb-1">Manufacturer</p>
              <span class="info--value font-weight-black">Coming soon</span>
            </v-col> -->
            <v-col cols="6">
              <p class="info--title subtitle-2 mb-1">MILEAGE SINCE INSTALLATION</p>
              <span class="info--value font-weight-black">{{ getOdometer() }}</span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    tyre: {
      type: Object,
      default: () => {}
    },
    message: {
      type: Object,
      default: () => {}
    },
    highlight: {
      type: Boolean,
      default: false
    },
    tpmsId: {
      type: Array,
      default: () => []
    },
    vehicleType: {
      type: Object,
      default: () => {}
    },
    tpmsChange: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      menu: false
    }
  },
  computed: {
    colors () {
      return [
        {
          name: 'grey',
          priority: 0,
          hex: '#9E9E9E'

        },
        {
          name: 'green',
          priority: 1,
          hex: '#4CAF50'
        },
        {
          name: 'yellow',
          priority: 2,
          hex: '#FFEE58'
        },
        {
          name: 'red',
          priority: 3,
          hex: '#C62828'
        }
      ]
    },
    sensorId () {
      if (this.tpmsId.length > 0) {
        // Get the name and cut the first two letters
        return this.tpmsId[0][this.tyre.name.toLowerCase()].slice(2)
      }
      return this.$t('common.noData')
    }
  },
  methods: {
    getFormatTemperature () {
      const temperature = this.getTemperature()
      return temperature === 'N/A' ? temperature : `${temperature} °C`
    },
    getFormatPressure () {
      const pressure = this.getPressure()
      return pressure === 'N/A' ? pressure : `${pressure} PSI`
    },
    getTemperature () {
      if (this.message?.tpmsParams) {
        const tpmsParams = this.message?.tpmsParams === null ? {} : this.message?.tpmsParams
        if (tpmsParams[`stpms${this.tyre.number}TyreTemp`]) {
          if (tpmsParams[`stpms${this.tyre.number}TyreTemp`] > 200) {
            return 'N/A'
          } else {
            return tpmsParams[`stpms${this.tyre.number}TyreTemp`]
          }
        }
      }
      return 'N/A'
    },
    getPressure () {
      if (this.message?.tpmsParams) {
        const tpmsParams = this.message?.tpmsParams === null ? {} : this.message?.tpmsParams
        if (tpmsParams[`stpms${this.tyre.number}TyrePres`]) {
          if ((tpmsParams[`stpms${this.tyre.number}TyrePres`] / 6.895).toFixed(2) > 200) {
            return 'N/A'
          } else {
            return (tpmsParams[`stpms${this.tyre.number}TyrePres`] / 6.895).toFixed(2)
          }
        }
      }
      return 'N/A'
    },
    getPsi () {
      // Validate if the vehicle type is null, in that case show No configured
      if (this.vehicleType === null) {
        return 'No configured'
      }
      // Validate if the vehicle type is not null, in that case show the psi if the tpms is activate
      if (this.vehicleType.tpms) {
        return `${this.vehicleType.placardPressure} PSI`
      }
    },
    getTyreColor () {
      let color = this.colors[0]
      const temperature = this.getTemperature()
      // Validate temperature
      if (temperature !== 'N/A') {
        if (temperature <= 75) {
          color = this.colors[1]
        } else if (temperature > 75 && temperature < 85) {
          color = this.colors[2]
        } else if (temperature >= 85) {
          color = this.colors[3]
        }
      }
      const pressure = this.getPressure()
      // Validate pressure
      if (pressure !== 'N/A') {
        if (pressure >= 110.5) {
          if (color.priority < this.colors[1].priority) {
            color = this.colors[1]
          }
        } else if (pressure > 101.4 && pressure < 110.5) {
          if (color.priority < this.colors[2].priority) {
            color = this.colors[2]
          }
        } else if (pressure <= 101.4) {
          if (color.priority < this.colors[3].priority) {
            color = this.colors[3]
          }
        }
      }
      return color.hex
    },

    getDate () {
      if (this.tpmsChange.length > 0) {
        if (this.tpmsChange.some(el => el.firstWheelPosition.toUpperCase() === this.tyre.name.toUpperCase())) {
          const receivedAt = this.tpmsChange.find(el => el.firstWheelPosition.toUpperCase() === this.tyre.name.toUpperCase())?.receivedAt
          if (receivedAt) {
            return this.formatDate(receivedAt)
          }
        }
      }
      return 'N/A'
    },

    getOdometer () {
      if (this.tpmsChange.length > 0) {
        if (this.tpmsChange.some(el => el.firstWheelPosition.toUpperCase() === this.tyre.name.toUpperCase())) {
          const odometer = this.tpmsChange.find(el => el.firstWheelPosition.toUpperCase() === this.tyre.name.toUpperCase())?.odometer
          if (odometer) {
            return `${odometer} km`
          }
        }
      }
      return 'N/A'
    },

    formatDate (date) {
      if (date) {
        return dayjs(date * 1000).format('Do MMMM YYYY - hh:mm a')
      } else {
        return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.text{
  text-align: left;
  font-size: 17px;
  letter-spacing: 0px;
  color: #787878;
  opacity: 1;
  margin: 0px;
}

.tyre_pill__card {
  transition: all 0.5s ease;
  border-radius: 20px !important;
  box-shadow: 0px 0px 6px #00000029 !important;
  border: 1px solid #707070 !important;

  &.highlight {
    box-shadow: 0px 0px 10px #73160ED5 !important;
    border: 4px solid #73160E !important;
  }
}

.info_menu__card{
  border: 1px solid #7070705E !important;
  border-radius: 15px !important;
  .info--title{
    text-transform: uppercase;
  }
  .info--value{
    font-size: 18px;
  }
}
</style>