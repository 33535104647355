function arrayToDict (array, key) {
  const result = {}
  for (let i = 0; i < array.length; i++) {
    result[array[i][key]] = array[i]
  }
  return result
} 

/*
  TPMS mutations
*/
export default {
  setTpmsEventsList (state, payload) {
    // Sort payload
    payload.sort(function (a, b) {
      if (a.receivedAt < b.receivedAt) {
        return -1
      }
      if (a.receivedAt > b.receivedAt) {
        return 1
      }
      return 0
    })
    state.list = payload
    state.dictList = Object.assign({}, arrayToDict(payload, 'id'))
    // console.log(state)
  },

  setUnitsToTheEvents (state, { rootState }) {
    console.log('setUnitsToTheEvents')
    console.log(state)
    // map underinflationEvents
    const events = []
    const tpmsWarningsTypes = [
      'LOW_PRESSURE',
      'OVERINFLATED',
      'EXTREME_LOW_PRESSURE',
      'OVERHEATED'
    ]
    const tpmsWarnings = {}
    const tpmsEvents = {}
    for (let i = 0; i < state.list.length; i++) {
      const event = state.list[i]
      events.push({
        ...event,
        unit: rootState.units.dictList[event.unitId]
      })
      
      // Filter the two types of events
      if (tpmsWarningsTypes.includes(event.eventType)) {
        if (tpmsWarnings[event.unitId] === undefined) {
          tpmsWarnings[event.unitId] = {}
          tpmsWarnings[event.unitId].unit = rootState.units.dictList[event.unitId]
          tpmsWarnings[event.unitId].events = []
        }
        tpmsWarnings[event.unitId].events.push({
          ...event,
          unit: rootState.units.dictList[event.unitId]
        })
      } else {
        console.log('event', event.eventType)
        if (tpmsEvents[event.unitId] === undefined) {
          tpmsEvents[event.unitId] = {}
          tpmsEvents[event.unitId].unit = rootState.units.dictList[event.unitId]
          tpmsEvents[event.unitId].events = []
        }
        tpmsEvents[event.unitId].events.push({
          ...event,
          unit: rootState.units.dictList[event.unitId]
        })
      }
    }
    state.list = events
    // Convert tpmsWarnings to array
    const tpmsWarningList = []
    for (const key in tpmsWarnings) {
      const unit = tpmsWarnings[key]
      unit.eventsType = []
      for (let i = 0; i < unit.events.length; i++) {
        const event = unit.events[i]
        if (!unit.eventsType.includes(event.eventType)) {
          unit.eventsType.push(event.eventType)
        }
      }
      tpmsWarningList.push(unit)
    }
    state.tpmsWarnings = tpmsWarningList
    // Convert tpmsEvents to array
    const tpmsEventList = []
    for (const key in tpmsEvents) {
      const unit = tpmsEvents[key]
      unit.eventsType = []
      for (let i = 0; i < unit.events.length; i++) {
        const event = unit.events[i]
        if (!unit.eventsType.includes(event.eventType)) {
          unit.eventsType.push(event.eventType)
        }
      }
      tpmsEventList.push(unit)
    }
    state.tpmsEvents = tpmsEventList
  },
  // eslint-disable-next-line no-unused-vars
  toggle (_, payload) {}
}