export default {
  list: [],
  selectedData: [],
  selectedGroups: [],
  selectedUnits: [],
  selectedTime: null,
  globalSearch: '',

  // objects
  checksObject: {},
  tasksObject: {},

  // dialogs
  checksDialog: false,
  assignDefectsDialog: false,
  tasksDialog: false
}
