<template>
  <v-list-item
    :key="index"
    three-line
    @dblclick="showDialog"
    @click="clickedElement"
  >
    <!-- <v-list-item-icon
          class="d-flex flex-column"
        >
          <v-icon
            class="primary--text"
            dark
            :large="!iconLabel"
          >
            {{ icon }}
          </v-icon>
          {{ iconLabel }}
        </v-list-item-icon> -->

    <v-list-item-content class="pa-1">
      <v-list-item-title class="d-flex align-center justify-space-between">
        <h3 :class="colorText">
          {{ title }}
        </h3>
      </v-list-item-title>
      <v-list-item-subtitle
        v-if="formatDateTime()"
        :class="colorText"
        style="text-align: initial;"
      >
        <h5>
          {{ formatDateTime() }}
        </h5>
      </v-list-item-subtitle>
      <v-list-item-subtitle
        v-if="subtitle || address !== 'the address was unable to retrieve'"
        :class="colorText"
        style="text-align: initial;"
      >
        <h5 v-if="subtitle">
          {{ subtitle }}
        </h5>
        <h5 v-else-if="address !== 'the address was unable to retrieve'">
          {{ address }}
        </h5>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action :style="`width: ${expand ? '338px' : '18%'}; height: 88px;`" class="d-flex flex-column align-center justify-center ma-0 pa-0">
      <div style="width: 100%;" class="d-flex align-center" :class="expand ? 'justify-space-between' : 'justify-end'">
        <template v-for="(item, index) in iconsList">
          <v-menu
            v-if="expand || index < 2"
            :key="index"
            open-on-hover
            top
            offset-y
          >
            <template #activator="{ on, attrs }">
              <v-icon :color="item.color" :class="expand ? '' : 'mx-1'" v-bind="attrs" v-on="on">{{ item.icon }}</v-icon>
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-title>{{ item.label }}: {{ item.value }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </div>
    </v-list-item-action>
  </v-list-item>
  <!-- <v-menu offset-y>
    <template #activator="{ on, attrs }">
    </template>
    <v-list dense>
      <v-list-item @click="showDialog">
        <v-list-item-icon>
          <v-icon>mdi-eye</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Show info</v-list-item-title>
      </v-list-item>
      <v-list-item @click="clickedElement">
        <v-list-item-icon>
          <v-icon>mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Show in map</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu> -->
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import getAddress from 'GraphQL/queries/address/getAddress.gql'
import dayjs from 'dayjs'
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export default {
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subtitle: {
      type: String,
      default: () => ''
    },
    icon: {
      type: String,
      default: () => ''
    },
    iconLabel: {
      type: String,
      default: () => ''
    },
    index: {
      type: Number,
      default: () => 0
    },
    date: {
      type: Number,
      default: () => undefined
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    unit: {
      type: Object,
      default: () => {}
    },
    expand: {
      type: Boolean,
      default: true
    }
  },
  asyncComputed: {
    address: {
      get () {
        console.log(`get address ${this.unit.name}`)
        const positionObject = {}
        let geofenceIds = []
        if (this.unit.telemetry?.address) {
          return this.unit.telemetry?.address
        }
        if (this.unit.telemetry?.position?.latitude) {
          positionObject.latitude = this.unit.telemetry.position.latitude
          positionObject.longitude = this.unit.telemetry.position.longitude
          geofenceIds = this.unit.telemetry.geofencesIds
        } else {
          return 'the address was unable to retrieve'
        }
        const object = Object.assign({}, this.unit)
        // Get the address using apollo
        return this.$apollo.query({
          query: getAddress,
          variables: {
            apiToken: this.user.apiToken,
            position: positionObject,
            geofenceIds
          }
        }).then(response => {
          const { status, result } = response.data.getAddress
          switch (status) {
            case 'OK':
              object.telemetry.address = result
              // if (object.id === this.drawerUnit.id) {
              //   this.$store.commit('units/updateUnit', object)
              // }
              // this.$store.dispatch('units/setUnitsEvent', this.$store.state)
              return result
            case 'ACCESSDENIED':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.accessDenied'), color: 'red darken-2' })
              break
            case 'BADREQUEST':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
              break
            case 'UNPROCESSABLE':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'orange darken-2' })
              break
            case 'INTERNALERROR':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
              break
          }
        }).catch(err => {
          console.error(err)
          this.$store.commit('toggleSnackbar', { message: this.$i18n.t('helpers.errors.disaster'), color: 'red darken-2' })
        }).finally(() => {
        })
      },
      default: 'Loading...'
    }
  },
  data () {
    return {
      today: null
    }
  },
  computed: {
    ...mapState(['isDark']),
    ...mapGetters(['colorText']),
    user () { return this.$store.state.session },
    dtcCodes () { return this.$store.state.dtcs.codeDictList },
    drawerUnit () { return this.$store.state.units.unit },
    iconsList () {
      return [ 
        {
          color: this.getSignalColor(),
          icon: 'mdi-signal-variant',
          value: this.getSignal(),
          label: 'Last sync update',
          onClick: () => {}
        },
        {
          color: this.getSpeedColor(),
          icon: this.getSpeedIcon(),
          value: this.getSpeed(),
          label: 'Speed',
          onClick: () => {}
        },
        {
          color: this.getBrakePerfomanceRateColor(),
          icon: 'mdi-car-brake-alert',
          value: this.getBrakePerfomanceRate(),
          label: 'EBPMS',
          onClick: () => {}
        },
        {
          color: this.getTyreAlertColor(),
          icon: 'mdi-car-tire-alert',
          value: this.getTyreAlert(),
          label: 'Tyre alerts',
          onClick: () => {}
        },
        {
          color: this.getDtcCodeColor(),
          icon: 'mdi-hazard-lights',
          value: this.getDtcCode(),
          label: 'DTC code',
          onClick: () => {}
        },
        {
          color: this.getServiceDateColor(),
          icon: 'mdi-wrench',
          value: this.getServiceDate(),
          label: 'Service',
          onClick: () => {}
        },
        {
          color: this.getWeightColor(),
          icon: 'mdi-weight',
          value: this.getWeight(),
          label: 'Weight',
          onClick: () => {}
        },
        {
          color: this.getOdometerColor(),
          icon: 'mdi-speedometer',
          value: this.getOdometer(),
          label: 'Odometer',
          onClick: () => {}
        }
      ]
    },
    colors () {
      return [
        {
          name: 'grey',
          priority: 0,
          hex: '#9E9E9E'

        },
        {
          name: 'green',
          priority: 1,
          hex: '#4CAF50'
        },
        {
          name: 'amber',
          priority: 2,
          hex: '#FFEE58'
        },
        {
          name: 'red',
          priority: 3,
          hex: '#C62828'
        }
      ]
    },
    tyreArrays () {
      return [
        {
          name: 'NSR',
          number: '37'
        },
        {
          name: 'NSM',
          number: '27'
        },
        {
          name: 'NSF',
          number: '17'
        },
        {
          name: 'OSR',
          number: '39'
        },
        {
          name: 'OSM',
          number: '29'
        },
        {
          name: 'OSF',
          number: '19'
        }
      ]
    }
  },

  created () {
    this.today = dayjs()
  },
  methods: {
    formatDateTime () {
      if (this.unit.telemetry?.receivedAt) {
        const date = this.unit.telemetry.receivedAt * 1000
        return dayjs(date).format('Do MMMM YYYY - hh:mm a')
      } else {
        // return 'Date not available'
        return false
      }
    },
    formatServiceDate (date, interval) {
      if (date) {
        return dayjs(date).add(interval, 'day').format('Do MMMM YYYY')
      } else {
        return ''
      }
    },
    getWeight () {
      if (this.unit.telemetry?.axeLoadSum) {
        return `${this.unit.telemetry.axeLoadSum}kg`
      } else {
        return 'Not Available'
      }
    },
    getOdometer () {
      return this.unit?.telemetry?.odometer ? `${this.unit.telemetry.odometer} km` : this.$t('units.message.collectingData')
    },
    onClickHandler () {
      this.onClick(this.unit)
    },
    clickedElement () {
      this.$emit('clicked-element', this.unit)
    },
    getBrake () {
      if (this.unit.telemetry?.position.speed) {
        return `${this.unit.telemetry.position.speed} km/h`
      } else {
        return 'Not available'
      }
    },
    getSignal () {
      if (this.unit.telemetry?.receivedAt) {
        const day = dayjs(this.unit.telemetry?.receivedAt * 1000)
        if (this.today.diff(day, 'minute') > 30) {
          return 'Disconnected'
        } else {
          return 'Connected'
        }
      } else {
        return 'Disconnected'
      }
    },
    getSpeed () {
      if (this.unit.telemetry?.receivedAt) {
        const day = dayjs(this.unit.telemetry?.receivedAt * 1000)
        if (this.today.diff(day, 'minute') > 30) {
          return 'Parked'
        } else {
          if (this.unit.telemetry?.position.speed !== null && this.unit.telemetry?.position.speed !== undefined) {
            return `${this.unit.telemetry.position.speed} km/h`
          } else {
            return 'Not available'
          }
        }
      } else {
        return 'Parked'
      }
    },

    getSpeedIcon () {
      if (this.unit.telemetry?.receivedAt) {
        const day = dayjs(this.unit.telemetry?.receivedAt * 1000)
        if (this.today.diff(day, 'minute') > 30) {
          return 'mdi-alpha-p-circle-outline'
        } else {
          return 'mdi-speedometer'
        }
      } else {
        return 'mdi-alpha-p-circle-outline'
      }
    },
    getBrakePerfomanceRate () {
      if (this.unit.brakePerformanceRate?.rate) {
        return `${Math.round(this.unit.brakePerformanceRate.rate)}%`
      } else {
        return 'Not Available'
      }
    },
    getDtcCode () {
      if (this.unit.dtcEvent && this.unit.telemetry.dtcEvent) {
        const dtcCode = this.dtcCodes[this.unit.dtcEvent.dtcCodeId]
        return dtcCode.shortName
      } else {
        return '--'
      }
    },

    getSignalColor () {
      if (this.unit.telemetry?.receivedAt) {
        const day = dayjs(this.unit.telemetry?.receivedAt * 1000)
        if (this.today.diff(day, 'minute') > 30) {
          return 'grey'
        } else {
          return 'green'
        }
      } else {
        return 'grey'
      }
    },

    getSpeedColor () {
      if (this.unit.telemetry?.receivedAt) {
        const day = dayjs(this.unit.telemetry?.receivedAt * 1000)
        if (this.today.diff(day, 'minute') > 30) {
          return 'blue'
        }
      } 
      if (this.unit.telemetry?.position.speed !== null && this.unit.telemetry?.position.speed !== undefined) {
        if (this.unit.telemetry.position.speed === 0) {
          return 'red'
        } else {
          return 'green'
        }
      } else {
        return 'grey'
      }
    },
    getBrakePerfomanceRateColor () {
      if (this.unit.brakePerformanceRate?.rate) {
        const value = this.unit.brakePerformanceRate.rate
        if (value < 44.99) {
          return 'red'
        } else if (value > 45 && value < 46.99) {
          return 'amber'
        } else {
          return 'green'
        }
      } else {
        return 'grey'
      }
    },
    getDtcCodeColor () {
      if (this.unit.dtcEvent && this.unit.telemetry.dtcEvent) {
        if (this.unit.dtcEvent.dtcAmberState === 16 || this.unit.dtcEvent.dtcAmberState === 32) {
          return 'amber'
        } else if (this.unit.dtcEvent.dtcRedState === 4 || this.unit.dtcEvent.dtcRedState === 32) {
          return 'red'
        } else {
          return 'grey'
        }
      } else {
        return 'green'
      }
    },
    getWeightColor () {
      if (this.unit.telemetry?.axeLoadSum && this.unit.trailerProfile?.weight) {
        if (this.unit.telemetry.axeLoadSum / this.unit.trailerProfile.weight > 1) {
          return 'red'
        } else {
          return 'green'
        }
      } else {
        return 'grey'
      }
    },
    getOdometerColor () {
      return this.unit?.telemetry?.odometer ? 'blue' : 'grey'
    },

    // Get Service Date functions
    getServiceDate () {
      if (this.unit.trailerProfile.lastService && this.unit.trailerProfile.serviceInterval) {
        return this.formatServiceDate(this.unit.trailerProfile.lastService, this.unit.trailerProfile.serviceInterval)
      } else {
        return 'Not Set'
      }
    },
    getServiceDateColor () {
      if (this.unit.trailerProfile.lastService && this.unit.trailerProfile.serviceInterval) {
        const date = this.unit.trailerProfile.lastService
        const interval = this.unit.trailerProfile.serviceInterval
        const serviceDate = dayjs(date).add(interval, 'day').unix()
        const now = dayjs().unix()
        if (serviceDate - now < (3600 * 24 * 5)) {
          return 'red'
        } else if (serviceDate - now < (3600 * 24 * 11)) {
          return 'amber'
        } else {
          return 'green'
        }
      } else {
        return 'grey'
      }
    },
    getTemperature (message, tyreNumber) {
      if (message?.tpmsParams) {
        const tpmsParams = message?.tpmsParams === null ? {} : message?.tpmsParams
        if (tpmsParams[`stpms${tyreNumber}TyreTemp`]) {
          if (tpmsParams[`stpms${tyreNumber}TyreTemp`] > 200) {
            return this.$t('units.message.collectingData')
          } else {
            return `${tpmsParams[`stpms${tyreNumber}TyreTemp`]}°C`
          }
        }
      }
      return this.$t('units.message.collectingData')
    },
    getPressure (message, tyreNumber) {
      if (message?.tpmsParams) {
        const tpmsParams = message?.tpmsParams === null ? {} : message?.tpmsParams
        if (tpmsParams[`stpms${tyreNumber}TyrePres`]) {
          if ((tpmsParams[`stpms${tyreNumber}TyrePres`] / 6.895).toFixed(2) > 200) {
            return this.$t('units.message.collectingData')
          } else {
            return (tpmsParams[`stpms${tyreNumber}TyrePres`] / 6.895).toFixed(2)
          }
        }
      }
      return this.$t('units.message.collectingData')
    },
    getTyreAlert () {
      let tyreAlertCounts = 0
      if (this.unit.tpmsTelemetry) {
        for (const i in this.tyreArrays) {
          let color = this.colors[0]
          const temperature = this.getTemperature(this.unit.tpmsTelemetry, this.tyreArrays[i].number)
          // Validate temperature
          if (temperature !== this.$t('units.message.collectingData')) {
            if (temperature <= 75) {
              color = this.colors[1]
            } else if (temperature > 75 && temperature < 85) {
              color = this.colors[2]
            } else if (temperature >= 85) {
              color = this.colors[3]
            }
          }
          const pressure = this.getPressure(this.unit.tpmsTelemetry, this.tyreArrays[i].number)
          // Validate pressure
          if (pressure !== this.$t('units.message.collectingData')) {
            if (pressure >= 110.5) {
              if (color.priority < this.colors[1].priority) {
                color = this.colors[1]
              }
            } else if (pressure > 101.4 && pressure < 110.5) {
              if (color.priority < this.colors[2].priority) {
                color = this.colors[2]
              }
            } else if (pressure <= 101.4) {
              if (color.priority < this.colors[3].priority) {
                color = this.colors[3]
              }
            }
          }
          if (color.priority > 1) {
            tyreAlertCounts++
          }
        }
        return tyreAlertCounts
      } else {
        return 'Not Available'
      }
    },
    getTyreAlertColor () {
      let color = this.colors[0]
      if (this.unit.tpmsTelemetry) {
        for (const i in this.tyreArrays) {
          const temperature = this.getTemperature(this.unit.tpmsTelemetry, this.tyreArrays[i].number)
          // Validate temperature
          if (temperature !== this.$t('units.message.collectingData')) {
            if (temperature <= 75) {
              if (color.priority < this.colors[1].priority) {
                color = this.colors[1]
              }
            } else if (temperature > 75 && temperature < 85) {
              if (color.priority < this.colors[2].priority) {
                color = this.colors[2]
              }
            } else if (temperature >= 85) {
              if (color.priority < this.colors[3].priority) {
                color = this.colors[3]
              }
            }
          }
          const pressure = this.getPressure(this.unit.tpmsTelemetry, this.tyreArrays[i].number)
          // Validate pressure
          if (pressure !== this.$t('units.message.collectingData')) {
            if (pressure >= 110.5) {
              if (color.priority < this.colors[1].priority) {
                color = this.colors[1]
              }
            } else if (pressure > 101.4 && pressure < 110.5) {
              if (color.priority < this.colors[2].priority) {
                color = this.colors[2]
              }
            } else if (pressure <= 101.4) {
              if (color.priority < this.colors[3].priority) {
                color = this.colors[3]
              }
            }
          }
        }
        return color.name
      } else {
        return 'grey'
      }
    },
    async showDialog () {
      console.log('showDialog from map')
      this.$showUnitDialog(this.unit)
    }

  }
}
</script>