// Libraries
import router from '@/plugins/router'
import { i18n } from '@/plugins/i18n'
import { apollo } from '@/plugins/apollo'

// graphql files
// import validate from 'GraphQL/mutations/profile/validate.gql'
import login from 'GraphQL/mutations/session/login.gql'
import logout from 'GraphQL/mutations/session/logout.gql'
import getSession from 'GraphQL/queries/session/getSession.gql'
import editConfiguration from 'GraphQL/mutations/dashboardConfiguration/edit.gql'

export default {
  async logout ({ commit, state }) {
    if (!state.token.token) {
      localStorage.removeItem('token.token')
      localStorage.removeItem('token.validBefore')
      commit('clearEntity')
      commit('units/emptySelectedUnits', false, { root: true })
      router.push('/Login')
      return
    }

    await commit('setLoading', true, { root: true })
    await apollo.mutate({
      mutation: logout,
      variables: {
        apiToken: state.token.token
      }
    }).then(_ => {
      localStorage.removeItem('token.token')
      localStorage.removeItem('token.validBefore')
      commit('clearEntity')
      commit('units/emptySelectedUnits', false, { root: true })
      router.push('/Login')
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async login ({ dispatch, commit }, payload) {
    commit('setLoading', true, { root: true })

    await apollo.mutate({
      mutation: login,
      variables: {
        username: payload.username,
        password: payload.password
      }
    }).then(response => {
      const { status, result } = response.data.login

      switch (status) {
        case 'OK':
          localStorage.setItem('token.token', result.apiToken)
          // localStorage.setItem('token.validBefore', result.token.validBefore)
          commit('setEntity', result)
          dispatch('loadData')

          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
          commit('setLoading', false, { root: true })
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
          commit('setLoading', false, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      
    })
  },

  async editConfiguration ({ commit }, payload) {
    await apollo.mutate({
      mutation: editConfiguration,
      variables: payload
    }).then(response => {
      const { status } = response.data.editDashboardConfiguration

      switch (status) {
        case 'OK':
          commit('toggleSnackbar', {
            message: i18n.t('profile.success'),
            color: 'green darken-2',
            duration: 10000
          }, { root: true }
          )
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      
    })
  },

  async loadEntity ({ dispatch, commit }, payload) {
    let apiToken = localStorage.getItem('token.token')
    const lastUrl = window.location.href.split('#')[1]
    if (lastUrl.includes('/Glance/View')) {
      commit('setLoading', false, { root: true })
      return
    }
    const url = new URL(window.location.href)
    if (url.hash.includes('api_token')) {
      const paramsString = url.hash.split('#')[1]
      const params = paramsString.split('&')
  
      // For parms to search for a api_token
      const apiTokenLoginAs = params.find(param => param.includes('api_token')).split('=')[1]
      console.log(apiTokenLoginAs)
      if (apiTokenLoginAs) {
        apiToken = apiTokenLoginAs
      }
    }
    if (!apiToken) {
      router.push('/Login')
      commit('setLoading', false, { root: true })
      return
    }
    
    await apollo.query({
      query: getSession,
      variables: {
        apiToken
      }
    }).then(response => {
      const { status, result } = response.data.getSession

      switch (status) {
        case 'OK':
          localStorage.setItem('token.token', result.apiToken)
          if (result.language) {
            commit('setLanguage', result.language.code, { root: true })
          }
          commit('setEntity', result)

          if (!payload) {
            dispatch('loadData', payload)
          }
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.not_found'), color: 'warning' }, { root: true })
          commit('setLoading', false, { root: true }) 
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('session.expired'), color: 'orange darken-2' }, { root: true })
          dispatch('logout')
          commit('setLoading', false, { root: true }) 
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', null, { root: true })
          commit('setLoading', false, { root: true }) 
          break
        default:
          router.push('/Login')
          break
      }
    }).catch(err => {
      console.error(err)
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      // commit('setLoading', false, { root: true })
    })
  },
  async loadData ({ dispatch, commit }, payload) {
    const lastUrl = window.location.href.split('#')[1]
    await commit('setLoading', true, { root: true })
    dispatch('dtcs/getList', false, { root: true })
    dispatch('events/getList', false, { root: true })
    dispatch('historicVor/getList', false, { root: true })
    dispatch('dailyOdometers/getList', false, { root: true })
    dispatch('geofences/getList', false, { root: true })
    // await dispatch('emptyRunningTrips/getList', false, { root: true })
    // dispatch('stoodStatus/getList', false, { root: true })
    dispatch('notifications/getList', false, { root: true })
    dispatch('newReports/getList', false, { root: true })
    dispatch('scheduleReports/getList', false, { root: true })
    dispatch('groups/getList', false, { root: true })
    dispatch('tpmsEvents/getList', false, { root: true })
    dispatch('reports/getList', false, { root: true })
    dispatch('events/getTelemetryEventList', false, { root: true })
    dispatch('templates/getList', false, { root: true })
    dispatch('checkResults/getList', false, { root: true })
    dispatch('checkResultItems/getList', false, { root: true })
    dispatch('activityDrivers/getList', false, { root: true })
    dispatch('mechanics/getList', false, { root: true })
    dispatch('defectChecks/getList', false, { root: true })
    await dispatch('units/getList', false, { root: true })
    await commit('setDataHasLoaded', true, { root: true })
    console.log('Data has been loaded')
    await commit('setLoading', false, { root: true }) 

    if (payload === undefined) {
      let redirect = lastUrl

      if (lastUrl === '/Login') {
        redirect = '/Home'
      }

      router.push(redirect)
    } else {
      router.push('/Home')
    }
  }
}
