<template>
  <gm-expandable-list
    expanded-height="300px"
    :mandatory="mandatory"
    :title="$t('dashboard.tpmsEvents')"
    :value="tpmsEvent.length"
    :data="tpmsEvent"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #leadingTitle>
      <v-icon
        class="ml-3"
        color="yellow darken-2"
      >
        mdi-alert
      </v-icon>
    </template>
    <template #headerTrail>
      <div
        style="z-index: 10;"
        class="d-flex"
      >
      <v-autocomplete
          v-model="eventTypeFilter"
          :items="eventTypes"
          label="Filter by Event Type"
          outlined
          dense
          hide-details
          clearable
          item-text="name"
          item-value="value"
        />
      </div>
    </template>
    <template #default="{ item, index }">
      <gm-tyre-list-item
        :index="index"
        :title="item.unit.name"
        :subtitle="formatSubtitle(item)"
        icon="mdi-alert"
        :date="getUnitDate(item.events)"
        :onClick="showDialog"
        :onClickMenu="showDialog"
        :events="item.events"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import {
  ExpandableList,
  TpmsListItem
} from 'Components/monitor'

import dayjs from 'dayjs'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-tyre-list-item': TpmsListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    },
    search: {
      type: String,
      default: () => ''
    },
    sortValue: {
      type: String,
      default: () => ''
    },
    dailyValue: {
      type: Number,
      default: () => 0
    },
    dailyOptions: {
      type: Array,
      default: () => [
      ]
    },
    stateFilter: {
      type: String,
      default: () => 'ALL'
    }
  },
  data () {
    return {
      eventTypeFilter: 'ALL'
    }
  },
  computed: {
    units () { return this.$store.state.units.list },
    values () { return this.$store.state.tpmsEvents.tpmsEvents },
    tpmsEvent () {
      let result = this.sortByDateArray(this.sortByState(this.filteredByDate(this.values)), this.sortValue === 'desc' ? 2 : 0)

      if (this.search) {
        result = result
          .filter(el =>
            el.unit &&
            el.unit.name &&
            el.unit.name.toLowerCase().includes(this.search.toLowerCase())
          )
      }
      return result
      // return this.$store.state.tpmsEvents.tpmsEvents
    },
    eventTypes () {
      // Check the values of the events and return the unique values
      let list = [...new Set(this.values.map(item => item.eventsType).flat())]
      list = ['ALL', ...list]
      return list.map((item) => {
        return {
          name: this.$t(`tpmsWarning.${item}`),
          value: item
        }
      })
    }
  },
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (unit) {
      this.$showUnitDialog(unit.unit)
    },
    convertPressure (value) {
      return (value / 6.895).toFixed(2)
    },
    getUnitDate (events) {
      if (events.length > 0) {
        return events[0].receivedAt * 1000
      }
      return undefined
    },
    formatSubtitle (event) {
      if (event.eventsType.length > 0) {
        return event.eventsType.map((item) => {
          return this.$t(`tpmsWarning.${item}`)
        }).join(', ')
      }
    },
    getNextServiceDate (unit) {
      return dayjs(unit.trailerProfile.nextServiceDate).unix() * 1000
    },
    filteredByDate (array) {
      // filter array by dailyValue and dailyOptions
      return array.filter((el) => {
        const date = dayjs(el.events[0].receivedAt * 1000)
        const today = dayjs()
        switch (this.dailyOptions[this.dailyValue]) {
          case 'today':
            return date.isSame(today, 'day')
          case 'yesterday':
            return date.isSame(today.subtract(1, 'day'), 'day')
          case 'thisWeek':
            return date.isSame(today, 'week')
          case 'lastWeek':
            return date.isSame(today.subtract(1, 'week'), 'week')
          default:
            return true
        }
      })
    },
    sortByDateArray (array, val = 0) {
      const self = this
      array.sort(function (a, b) {
        if (self.getUnitDate(a.events) < self.getUnitDate(b.events)) {
          return -1 + val
        }
        if (self.getUnitDate(a.events) > self.getUnitDate(b.events)) {
          return 1 - val
        }
        return 0
      })
      return array
    },
    sortByState (array) {
      const finalList = []
      for (const item of array) {
        const unitEvents = []
        for (const event of item.events) {
          if (event.state === this.stateFilter || this.stateFilter === 'ALL') {
            unitEvents.push(event)
          }
        }
        if (unitEvents.length > 0) {
          const finalItem = { ...item }
          finalItem.events = unitEvents
          finalList.push(finalItem)
        }
      }
      return finalList
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>