<template>
  <div>
    <v-bottom-sheet
      v-model="openSheet"
    >
      <v-sheet
        class="rounded-lg rounded-b-0"
        height="90vh"
      >
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex">
              <h3>
                {{ unit.name }}
              </h3>
              <v-spacer />
              <!-- Settings icon button -->
              
              <!-- close icon button -->
              <v-btn
                icon
                @click="openSheet = false"
              >
                <v-icon color="#C11A1A">mdi-close</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex">
              <v-tabs
                v-model="currentTab"
                style="width: 80%;"
              >
                <!-- v-tab for each tabs element -->
                <v-tab
                  v-for="(tab, index) in tabs"
                  :key="`tab-${index}`"
                >
                  {{ tab.text }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="px-0" style="height: calc(100% - 36px); overflow-y: auto;">
          <v-tabs-items v-model="currentTab" class="pa-2 better-scrollbar" style="height: calc(100% - 64px); overflow-y: auto;">
            <v-tab-item
              key="tab-item-0"
              style="height: 100%"
            >
              <gm-summary-tab
                :sheetOpen="open"
                :unit="unit"
                :currentTab="currentTab"
              />
            </v-tab-item>
            <v-tab-item
              :key="`tab-item-1`"
            >
              <gm-trailer-profile-tab
                :sheetOpen="open"
                :unit="unit"
              />
            </v-tab-item>
            <v-tab-item
              :key="`tab-item-2`"
            >
              <gm-trips-tab
                :sheetOpen="open"
                :unit="unit"
                :tabKey="currentTab"
              />
            </v-tab-item>
            <v-tab-item
              :key="`tab-item-3`"
            >
              <gm-reports-tab
                :sheetOpen="open"
                :unit="unit"
              />
            </v-tab-item>
            <v-tab-item
              v-if="unit.trailerProfile.tpmsActive"
              key="tab-item-4"
            >
              <gm-tpms-tab
                :sheetOpen="open"
                :unit="unit"
              />
            </v-tab-item>
            <v-tab-item
              v-if="unit.trailerProfile.ebpmsActive"
              :key="`tab-item-5`"
            >
              <gm-ebpms-tab
                :unit="unit"
                :sheetOpen="open"
                :currentTab="currentTab"
              />
            </v-tab-item>
            <v-tab-item
              :key="`tab-item-6`"
            >
              <gm-events-tab
                :unit="unit"
                :sheetOpen="open"
                :currentTab="currentTab"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>

import {
  SummaryTab,
  TripsTab,
  ReportsTab,
  EbpmsTab,
  TrailerProfileTab,
  TpmsTab,
  EventsTab
} from 'Components/units/unit_sheet/tabs'

export default {
  components: {
    'gm-summary-tab': SummaryTab,
    'gm-trips-tab': TripsTab,
    'gm-reports-tab': ReportsTab,
    'gm-ebpms-tab': EbpmsTab,
    'gm-trailer-profile-tab': TrailerProfileTab,
    'gm-tpms-tab': TpmsTab,
    'gm-events-tab': EventsTab
  },
  props: {
    /**
     * prop to open the bottom sheet
     */
    open: {
      type: Boolean,
      required: true
    },
    unit: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      currentTab: 0
    }
  },
  computed: {
    /**
     * computed prop to open the bottom sheet
     */
    openSheet: {
      get () {
        return this.open
      },
      set (val) {
        this.$emit('update:open', val)
      }
    },
    tabs () {
      let tabs = [
        {
          text: 'Summary',
          value: 0
        },
        {
          text: 'Vehicle Profile',
          value: 1
        }
      ]
      
      tabs = [
        ...tabs,
        // trips tab
        {
          text: 'trips',
          value: 2
        },
        // reports tab
        {
          text: 'Reports',
          value: 3
        }
      ]
      if (this.unit?.trailerProfile?.tpmsActive && this.unit?.style === 'TRAILER') {
        tabs = [
          ...tabs,
          // tpms tab
          {
            text: 'TPMS',
            value: 4
          }
        ]
      }
      if (this.unit?.trailerProfile?.ebpmsActive && this.unit?.style === 'TRAILER') {
        tabs = [
          ...tabs,
          {
            text: 'Brake Performance',
            value: 5
          }
        ]
      }
      tabs = [
        ...tabs,
        {
          text: 'Events',
          value: 6
        }
      ]
      return tabs
    }

  },
  watch: {
    openSheet (value) {
      if (!value) {
        this.currentTab = 0
      }
    }
  }
}
</script>

<style>

</style>