import NotFound from '@/views/NotFound.vue'
import LoginLayout from '@/layouts/Login.vue'
import GlanceLayout from '@/layouts/Glance.vue'
import AppLayout from '@/layouts/Main.vue'

export default [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: 'Login',
        name: 'login',
        component: () => import(/* webpackPrfetch: true */ '@/views/Login.vue')
      },
      {
        path: 'Recovery',
        name: 'recovery',
        component: () => import(/* webpackChunkName: "recovery" */ '@/views/Recovery.vue')
      }
    ]
  },
  {
    path: '/Glance',
    component: GlanceLayout,
    children: [
      {
        path: 'View',
        name: 'GlanceView',
        component: () => import(/* webpackChunkName: "recovery" */ '@/views/GlanceView.vue')
      }
    ]
  },
  {
    path: '/Dashboard',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.home',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
      }
    ]
  },
  {
    path: '/Units',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.units',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Units.vue')
      }
    ]
  },
  {
    path: '/Geofences',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.geofences',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Geofences.vue')
      }
    ]
  },
  {
    path: '/Home',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.map',
        component: () => import(/* webpackChunkName: "home" */ '@/views/MapMonitor.vue')
      }
    ]
  },
  {
    path: '/Reports',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.reports',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Reports.vue')
      }
    ]
  },
  {
    path: '/NewReports',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.newReports',
        component: () => import(/* webpackChunkName: "newReports" */ '@/views/NewReports.vue')
      }
    ]
  },
  {
    path: '/Templates',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.templates',
        component: () => import(/* webpackChunkName: "templates" */ '@/views/Templates.vue')
      }
    ]
  },
  {
    path: '/Maintenance',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.maintenance',
        component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance.vue')
      }
    ]
  },
  // {
  //   path: '/Notifications',
  //   component: AppLayout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'monitor.notifications',
  //       component: () => import(/* webpackChunkName: "notifications" */ '@/views/Notifications.vue')
  //     }
  //   ]
  // },
  {
    path: '/NotificationsEvents',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.notifications',
        component: () => import(/* webpackChunkName: "notifications & events" */ '@/views/NotificationsEvents.vue')
      }
    ]
  },
  {
    path: '/DriverStatus',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.driverStatus',
        component: () => import(/* webpackChunkName: "driver status" */ '@/views/DriverStatus.vue')
      }
    ]
  },
  {
    path: '/Groups',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.groups',
        component: () => import(/* webpackChunkName: "groups" */ '@/views/Groups.vue')
      }
    ]
  },
  {
    path: '/History',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.history',
        component: () => import(/* webpackChunkName: "home" */ '@/views/History.vue')
      }
    ]
  },
  {
    path: '/ScheduleReports',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.scheduleReports',
        component: () => import(/* webpackChunkName: "home" */ '@/views/ScheduleReports.vue')
      }
    ]
  },
  // {
  //   path: '/Events',
  //   component: AppLayout,
  //   children: [
  //     {
  //       path: '',
  //       name: 'monitor.events',
  //       component: () => import(/* webpackChunkName: "events" */ '@/views/Events.vue')
  //     }
  //   ]
  // },
  {
    path: '/CheckResults',
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'monitor.checkResults',
        component: () => import(/* webpackChunkName: "events" */ '@/views/CheckResults.vue')
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: 'Profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
      }
    ]
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound
  }
]
