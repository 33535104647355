<template>
  <v-dialog
    :value="dialog"
    scrollable
    width="100%"
    max-width="400px"
    transition="dialog-transition"
    persistent
  >
    <v-card width="100%">
      <v-card-title>
        Confirmation Dialog
      </v-card-title>

      <v-card-text class="pa-4 better-scrollbar">
        Are you sure you want to continue and update the event with the following status and comment?
      </v-card-text>

      <v-card-actions class="justify-space-between">
        <v-btn text @click="dialog = false">
          {{ $t('actions.ignore') }}
        </v-btn>
        <v-btn color="green" text @click="updateEventMethod">
          {{ $t('actions.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { apollo } from '@/plugins/apollo'
import updateEvent from 'GraphQL/mutations/events/update.gql'
export default {
  model: {
    prop: 'showDialog',
    event: 'toggle-dialog'
  },

  props: {
    showDialog: {
      type: Boolean,
      default: () => false
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    eventType: {
      type: String,
      default: () => ''
    },
    eventItem: {
      type: Object,
      default: () => {}
    },
    closeDialogConfirmation: {
      type: Function,
      default: () => () => {}
    }
  },

  data () {
    return {
    }
  },

  computed: {
    ...mapState(['width', 'isDark']),
    user () { return this.$store.state.session },
    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    }
  },

  created () {},

  methods: {
    generateNumber (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    },

    closeDialog () {
      this.dialog = false
    },

    async updateEventMethod () {
      // Active generic loader on the system
      this.$store.commit('setLoading', true)
      let mutationResult = false
      // Update event 
      await apollo.mutate({
        mutation: updateEvent,
        variables: {
          apiToken: this.user.apiToken,
          data: {
            eventId: this.eventItem.id,
            eventType: this.eventType.toUpperCase(),
            state: this.eventItem.state,
            comment: this.eventItem.comment !== null ? this.eventItem.comment : ''
          }
        }
      }).then(response => {
        const { status, result } = response.data.updateEventStatus
        mutationResult = result
        console.log('mutationResult', mutationResult)
        switch (status) {
          case 'OK':
            // Get the info base on the event type
            switch (this.eventType.toLowerCase()) {
              case 'overweight':
                this.$store.dispatch('events/getList', true, { root: true })
                break
              case 'dtc':
                this.$store.dispatch('dtcs/getList', true, { root: true })
                break
              case 'tpms':
                this.$store.dispatch('tpmsEvents/getList', true, { root: true })
                break
              case 'brakePerformanceRate':
                this.$store.commit('setLoading', false)
                break
              default:
                break
            }
            this.dialog = false
            this.closeDialogConfirmation()
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('events.sucessUpdateState'), color: 'warning' }, { root: true })
            break
          case 'BADREQUEST':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.not_found'), color: 'warning' }, { root: true })
            this.$store.commit('setLoading', false, { root: true }) 
            break
          case 'NOTFOUND':
            this.$store.commit('toggleSnackbar', { message: this.$i18n.t('session.expired'), color: 'orange darken-2' }, { root: true })
            this.$store.commit('setLoading', false, { root: true }) 
            break
          case 'INTERNALERROR':
            this.$store.commit('toggleSnackbar', null, { root: true })
            this.$store.commit('setLoading', false, { root: true }) 
            break
          default:
            break
        }
      }).catch(err => {
        console.warn(err)
        this.$store.commit('toggleSnackbar', undefined, { root: true })
      }).finally(() => {
        if (!mutationResult) {
          this.$store.commit('setLoading', false)
        }
      })
    }
  }
}
</script>
