<template>
  <div>
    <v-row style="height: 100%;">
      <v-col cols="12">
        <h1 class="px-5">Events</h1>
      </v-col>
      <!-- <v-col cols="12" class="blue">
        {{ unitId }}
      </v-col> -->
      <!-- <v-col cols="12" class="pink">
        {{ $store.state.dtcs.redTimeList.today[0] }}
      </v-col> -->
      <!-- <template v-for="(object, index) in configurationArray">
        <v-col
          :key="`event-${index}`"
          cols="12"
          md="4"
          sm="6"
          xs="12"
        >
          <gm-list-renderer :unit-id="unitId" :listType="object.type" :index="index" :configurationMode="false" @update-configuration="updateConfigurationOption" />
        </v-col>
      </template> -->
      <!-- <v-col cols="12">
        {{ dtcEvents }}
      </v-col> -->
      <!-- <v-col cols="12">
        {{ overweightEvents }}
      </v-col> -->

      <v-col cols="12" class="pa-5 text-center">
        <!-- Select for Events type -->
        <v-autocomplete
          v-model="eventType"
          :items="eventOptions"
          dense
          :label="$t('tyreAnalisis.eventType')"
          outlined
          prepend-inner-icon="mdi-cube"
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" class="pa-5">
        <v-list>
          <template v-for="(event, i) in eventItemsFiltered">
            <v-divider v-if="i > 0" :key="`d-${i}`" />
            <v-list-item v-if="true" :key="i">
              <v-list-item-avatar>
                <v-icon :color="event.color">{{ event.icon }}</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <div class="d-flex align-center">
                  <div>
                    <v-list-item-title class="text-uppercase">
                      {{ event.title }}
                      <v-chip small outlined :color="event.color" class="ml-1 text-uppercase">
                        {{ event.type }}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ event.description }}
                    </v-list-item-subtitle>
                  </div>
                  <v-btn class="mx-2" color="amber" icon @click.stop="showCommentDialog(event, event.type)">
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                </div>
              </v-list-item-content>

              <v-list-item-action>
                <v-chip outlined>
                  {{ formatDate(event.date * 1000) }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-col>

      <!-- <v-col cols="12" class="pa-5">
        <v-timeline
          dense
          clipped
        >
          <v-timeline-item
            v-for="(event, i) in eventItems"
            :key="i"
            :color="event.color"
          >
            <template #icon>
              <v-icon>{{ event.icon }}</v-icon>
            </template>
            <v-row justify="space-between">
              <v-col class="d-flex flex-column" cols="7">
                <h3 class="mb-1">
                  {{ event.title }}
                  <v-chip small label :color="event.color" class="ml-1 text-uppercase">
                    {{ event.type }}
                    <v-icon class="ml-1" small>{{ !event.isFinished ? 'mdi-ray-start' : 'mdi-ray-end' }}</v-icon>
                  </v-chip>
                  <v-btn class="mx-2" color="amber" icon @click.stop="showCommentDialog()">
                    <v-icon>mdi-file-edit-outline</v-icon>
                  </v-btn>
                </h3>
                <p class="ma-0 font-weight-thin">
                  {{ event.description }}
                </p>
              </v-col>
              <v-col
                class="d-flex flex-column text-right"
                cols="5"
              >
                <div class="mb-1">
                  <v-chip outlined>
                    {{ formatDate(event.date * 1000) }}
                  </v-chip>
                </div>
                <p class="ma-0 pr-1">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ event.location }}
                </p>
              </v-col>
            </v-row>
          </v-timeline-item>
        </v-timeline>
      </v-col> -->
    </v-row>

    <gm-comment-dialog v-model="commentDialog" :event-type="selectedEventType" :item="selectedItem" />
  </div>
</template>

<script>
// import ListRenderer from './list/ListRenderer.vue'
import CommentDialog from 'Components/monitor/dialogs/CommentDialog.vue'

import dayjs from 'dayjs'

export default {
  
  components: {
    'gm-comment-dialog': CommentDialog

    // 'gm-list-renderer': ListRenderer
    // 'gm-tyre-pill': TyrePill,
    // 'gm-tpms-dialog': TpmsDialog,
    // 'gm-trailer-render': TrailerRender
  },
  props: {
    unit: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      hoverWheel: null,
      dialog: false,
      isEditting: true,
      configurationArray: [],
      commentDialog: false,
      eventType: null,
      selectedItem: null,
      eventTypeItem: null

    }
  },
  computed: {
    configuration () {
      return [
        {
          type: 'amberdtcs'
        },
        {
          type: 'reddtcs'
        },
        {
          type: 'overweightevents'
        },
        {
          type: 'tpmspressure'
        },
        {
          type: 'tpmstemperature'
        }
      ]
    },
    arrayTop () {
      return [
        {
          name: 'NSR',
          number: '37'
        },
        {
          name: 'NSM',
          number: '27'
        },
        {
          name: 'NSF',
          number: '17'
        }
      ]
    },
    arrayBottom () {
      return [
        {
          name: 'OSR',
          number: '39'
        },
        {
          name: 'OSM',
          number: '29'
        },
        {
          name: 'OSF',
          number: '19'
        }
      ]
    },

    unitId () {
      if ('id' in this.unit) {
        return this.unit.id
      }
      return null
    },

    dtcEvents () { return this.$store.state.dtcs.eventList },
    dtcCodes () { return this.$store.state.dtcs.codeDictList },
    overweightEvents () { return this.$store.state.events.eventList },
    tpmsEvents () { return this.$store.state.units.tpmsEvents },
    changeTpmsEvents () { return this.$store.state.units.changeTpmsEvents },
    eventItems () {
      let result = []
      const dtcEvents = this.dtcEvents.filter(event => {
        return event.unitId === this.unitId
      })
      // DTC events
      for (let i = 0; i < dtcEvents.length; i++) {
        result.push({
          id: dtcEvents[i].id,
          title: 'DTC Event',
          description: this.getDtcName(dtcEvents[i]),
          type: 'DTC',
          color: this.getDtcCodeColor(dtcEvents[i]),
          date: dtcEvents[i].activatedAt,
          location: 'Lorem, ipsum dolor',
          icon: 'mdi-alert-circle-outline',
          comments: dtcEvents[i].comments
        })
      }

      const overweightEvents = this.overweightEvents.filter(event => event.unitId === this.unitId)
      // Overweight Events
      for (let i = 0; i < overweightEvents.length; i++) {
        result.push({
          id: overweightEvents[i].id,
          title: 'Overweight Event',
          description: `${overweightEvents[i].axeLoadSum} kg ${this.getWeightEventPercentage(overweightEvents[i])} %`,
          type: 'OVERWEIGHT',
          color: 'grey',
          date: overweightEvents[i].receivedAt,
          icon: 'mdi-weight',
          comments: overweightEvents[i].comments
        })
      }
      
      const brakePerformanceRates = this.unit.brakePerformanceRates

      // Brake Performance Rate Events
      for (let i = 0; i < brakePerformanceRates.length; i++) {
        result.push({
          id: brakePerformanceRates[i].id,
          title: 'Brake Performance Rate Calculation Event',
          description: `${brakePerformanceRates[i].rate.toFixed(2)} %`,
          type: 'BRAKEPERFORMANCERATE',
          color: 'grey',
          date: brakePerformanceRates[i].calculatedAt,
          icon: 'mdi-car-brake-alert',
          comments: brakePerformanceRates[i].comments
        })
      }
      const brakePerformanceResets = this.unit.brakePerformanceResets

      // Brake Performance Reset Events
      for (let i = 0; i < brakePerformanceResets.length; i++) {
        result.push({
          id: brakePerformanceResets[i].id,
          title: 'Brake Performance Rate Reset Event',
          description: '',
          type: 'BRAKEPERFORMANCERESET',
          color: 'grey',
          date: brakePerformanceResets[i].createdAt,
          icon: 'mdi-restart'
        })
      }

      // Add tpms events
      const tpmsEvents = this.$store.state.units.tpmsEvents
      for (let i = 0; i < tpmsEvents.length; i++) {
        result.push({
          id: tpmsEvents[i].id,
          title: `TPMS Event ${tpmsEvents[i].wheelPosition} - ${tpmsEvents[i].eventType.replaceAll('_', ' ')}`,
          description: '',
          type: tpmsEvents[i].eventType,
          color: 'grey',
          date: tpmsEvents[i].receivedAt,
          icon: 'mdi-car-tire-alert',
          comments: tpmsEvents[i].comments  
        })
      }

      // Add change tpms events
      for (let i = 0; i < this.changeTpmsEvents.length; i++) {
        result.push({
          id: this.changeTpmsEvents[i].id,
          title: `Change TPMS Event ${this.changeTpmsEvents[i].firstWheelPosition} - ${this.changeTpmsEvents[i].secondWheelPosition} - ${this.changeTpmsEvents[i].eventType.replaceAll('_', ' ')}`,
          description: '',
          type: this.changeTpmsEvents[i].eventType,
          color: 'grey',
          date: this.changeTpmsEvents[i].receivedAt,
          icon: 'mdi-car-tire-alert',
          comments: this.changeTpmsEvents[i].comments
        })
      }
      result = result.sort((a, b) => b.date - a.date)
      return result
    },

    eventItemsFiltered () {
      const events = this.eventItems
      const filteredEvents = []
      for (const i in events) {
        const event = events[i]
        let validation = true
        if (this.eventType) {
          if (event.type !== this.eventType) {
            validation = false
          }
        }
        if (validation) {
          filteredEvents.push(event)
        }
      }
      return filteredEvents
    },

    eventOptions () {
      // null,
      // 'MISSING',
      // 'ROTATION',
      // 'REPLACEMENT',
      // 'SWAP'
      return [
        { value: null, text: this.$t('tyreAnalisis.allEvents') },
        { value: 'DTC', text: this.$t('eventAnalisis.events.DTC') },
        { value: 'OVERWEIGHT', text: this.$t('eventAnalisis.events.OVERWEIGHT') },
        { value: 'BRAKEPERFORMANCERATE', text: this.$t('eventAnalisis.events.BRAKEPERFORMANCERATE') },
        { value: 'BRAKEPERFORMANCERESET', text: this.$t('eventAnalisis.events.BRAKEPERFORMANCERESET') },
        { value: 'MISSING', text: this.$t('tyreAnalisis.events.MISSING') },
        { value: 'ROTATION', text: this.$t('tyreAnalisis.events.ROTATION') },
        { value: 'REPLACEMENT', text: this.$t('tyreAnalisis.events.REPLACEMENT') },
        { value: 'SWAP', text: this.$t('tyreAnalisis.events.SWAP') },
        { value: 'LOW_PRESSURE', text: this.$t('tyreAnalisis.events.LOW_PRESSURE') },
        { value: 'EXTREME_LOW_PRESSURE', text: this.$t('tyreAnalisis.events.EXTREME_LOW_PRESSURE') },
        { value: 'OVERINFLATED', text: this.$t('tyreAnalisis.events.OVERINFLATED') },
        { value: 'BLOWOUT', text: this.$t('tyreAnalisis.events.BLOWOUT') },
        { value: 'FAST_PRESSURE_LOST', text: this.$t('tyreAnalisis.events.FAST_PRESSURE_LOST') }
      ]
    }
  },
  mounted () {
    this.configurationArray = this.configuration
  },
  methods: {
    wheelClick (_) {
      // this.$emit('wheel-click', wheel)
    },
    wheelHover (wheel) {
      // this.$emit('wheel-hover', wheel)
      this.hoverWheel = wheel
      // console.log('hover enter', wheel)
    },
    wheelMouseout (_) {
      this.hoverWheel = null
      // console.log('hover out')
      // this.$emit('wheel-mouseout')
    },
    updateConfigurationOption (index, value) {
      this.configurationArray[index].type = value
    },
    updateConfiguration () {
    },

    getDtcCodeColor (unit) {
      if (unit.dtcAmberState === 16 || unit.dtcAmberState === 32) {
        return 'amber'
      } else if (unit.dtcRedState === 4 || unit.dtcRedState === 32) {
        return 'red'
      } else {
        return 'grey'
      }
    },

    getDtcName (event) {
      console.log(event)
      return this.dtcCodes[event.dtcCodeId].shortName
    },

    getWeightEventPercentage (event) {
      const difference = event.axeLoadSum - event.maxWeightAllowed
      return ((difference / event.maxWeightAllowed) * 100).toFixed(0)
    },

    formatDate (unix) {
      return dayjs(unix).format('DD/MM/YYYY HH:mm:ss')
    },

    showCommentDialog (item, eventType) {
      this.selectedEventType = eventType
      this.selectedItem = item
      this.commentDialog = true
    },
    calculateEvents () {
      const events = []
      // For in tpmsEvents
      // for (const i in this.tpmsEvents) {
      //   const event = this.tpmsEvents[i]
      //   events.push({
      //     receivedAt: event.receivedAt,
      //     type: event.eventType,
      //     firstPosition: event.wheelPosition,
      //     secondPosition: event.wheelPosition,
      //     samePosition: true
      //   })
      // }
      // Sort by receivedAt
      events.sort((a, b) => {
        return new Date(b.receivedAt * 1000) - new Date(a.receivedAt * 1000)
      })
      console.log('events', events)
      // this.events = events
      return events
    }
  }
}
</script>