<template>
  <gm-expandable-list
    expanded-height="300px"
    :mandatory="mandatory"
    :title="$t('dashboard.overheatingEvents')"
    :value="overheatingEvents.length"
    :data="overheatingEvents"
    :showDelete="configurationMode"
    :panel-default-open="panelDefaultOpen"
    :expantion-absolute="expantionAbsolute"
    @clean-option="cleanOption"
  >
    <template #leadingTitle>
      <v-icon
        class="ml-3"
        color="yellow darken-2"
      >
        mdi-alert
      </v-icon>
    </template>
    <template #default="{ item, index }">
      <gm-tyre-list-item
        :index="index"
        :value="item.temperature"
        icon="mdi-truck"
        :icon-label="item.unit.name"
        :date="item.receivedAt * 1000"
        :is-amber="item.isAmber"
        measureUnit="°C"
        :subtitle="item.position.toUpperCase()"
        @click.native="showDialog(item)"
      />
    </template>
  </gm-expandable-list>
</template>

<script>

import {
  ExpandableList,
  TyreListItem
} from 'Components/monitor'

export default {
  components: {
    'gm-expandable-list': ExpandableList,
    'gm-tyre-list-item': TyreListItem
  },
  props: {
    configurationMode: {
      type: Boolean,
      default: () => false
    },
    panelDefaultOpen: {
      type: Boolean,
      default: () => true
    },
    expantionAbsolute: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    units () { return this.$store.state.units.list },
    overheatingEvents () {
      return this.$store.state.tpmsEvents.overheatingEvents
    }
  },
  methods: {
    cleanOption (value) {
      this.$emit('clean-option', value)
    },
    showDialog (unit) {
      this.$showUnitDialog(unit.unit)
    }
  }
}
</script>

<style lang="scss" scoped>

.unit__icon{
  font-size: 28px;
}

.unit__title{
  justify-content: center;
  font-size: 30px;
  font-weight: 700 !important;
}

.unit__subtitle {
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>