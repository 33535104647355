<template>
  <v-card
    :style="`height: ${height}`"
    style="overflow: hidden;"
    class="pa-2"
  >
    <v-card-title style="background: rgba(115, 22, 14, 0.25);">
      <h3
        style="font-family: Lato; font-weight: 700 !important;"
      >
        {{ title.toUpperCase() }}
      </h3>
      <v-spacer />
      <v-btn
        icon
        @click="toogleExpand"
      >
        <v-icon>{{ expand ? 'mdi-arrow-expand-left' : 'mdi-arrow-expand-right' }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-row no-gutters>
      <v-col cols="12" class="pb-2">
        <hr class="mx-2 primary striped--divider">
      </v-col>
      <v-col cols="6" class="pa-2">
        <v-autocomplete
          v-model="groupsFilter"
          :items="groups"
          dense
          label="Filter by"
          outlined
          hide-details
          item-text="name"
          item-value="id"
        />
      </v-col>
      <v-col cols="6" class="pa-2 d-flex justify-end align-center">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn :color="toggleSearch ? 'primary' : ''" icon v-bind="attrs" v-on="on" @click="toggleAction('search')">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
          <span>Search</span>
        </v-tooltip>
        <!-- <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="openGroupsDialog()">
              <v-icon>mdi-group</v-icon>
            </v-btn>
          </template>
          <span>New group</span>
        </v-tooltip> -->
        <!-- <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="viewAllElements">
              <v-icon>mdi-map</v-icon>
            </v-btn>
          </template>
          <span>View all Elements</span>
        </v-tooltip> -->
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-btn :color="toggleOrder ? 'primary' : ''" icon v-bind="attrs" v-on="on" @click="toggleAction('order')">
              <!-- <v-icon>{{ sortValue === 'name_asc' ? 'mdi-order-alphabetical-ascending' : 'mdi-order-alphabetical-descending' }}</v-icon> -->
              <v-icon>mdi-order-alphabetical-ascending</v-icon>
            </v-btn>
          </template>
          <span>Alphabetical Order</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2">
        <v-divider />
      </v-col>
      <v-col v-if="toggleOrder" key="order" cols="12" class="pa-2">
        <v-select
          v-model="sortValue"
          :items="filters"
          dense
          label="Filter by"
          outlined
          hide-details
          item-text="label"
          item-value="value"
        >
          <template #selection="{item}">
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template #item="{item}">
            <v-list-item-content>
              <v-list-item-title>
                {{ item.label }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>
                {{ item.icon }}
              </v-icon>
            </v-list-item-action>
          </template>
        </v-select>
      </v-col>
      <v-col v-if="toggleSearch" key="search" cols="12" class="pa-2">
        <v-text-field
          v-model="search"
          label="Search Unit"
          outlined
          dense
          hide-details
          prepend-inner-icon="mdi-magnify"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 py-0">
        <v-list-item dense class="px-0">
          <v-list-item-content>
            <div>
              <small class="ml-3 font-weight-bold">Unit</small>
            </div>
          </v-list-item-content>
          <v-list-item-action>
            <div class="d-flex align-center justify-space-between">
              <small class="px-1 unit-list-title text-truncate">Last Sync</small>
              <small class="px-1 unit-list-title text-truncate">Speed</small>
              <template v-if="expand">
                <small class="px-1 unit-list-title text-truncate">EBPMS</small>
                <small class="px-1 unit-list-title text-truncate">Tyre alert</small>
                <small class="px-1 unit-list-title text-truncate">DTC code</small>
                <small class="px-1 unit-list-title text-truncate">Service</small>
                <small class="px-1 unit-list-title text-truncate">Weight</small>
                <small class="px-1 unit-list-title text-truncate">Odometer</small>
              </template>
            </div>
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
    <v-list
      class="list--container scrollbar__seamless"
    >
      <v-virtual-scroll
        :height="`calc(${height} - ${toggleSearch || toggleOrder ? 320 : 220}px)`"
        item-height="89"
        :bench="3"
        class="scrollbar__seamless"
        :items="units"
      >
        <template #default="{ item, index }">
          <gm-monitor-list-item
            :key="'list__' + index"
            :class="isItemSelected(item) ? 'grey lighten-3' : ''"
            :index="index"
            :title="item.name"
            icon="mdi-truck"
            :icon-label="item.iconLabel"
            :unit="item"
            :expand="expand"
            :onClick="(unit) => showDetail(unit)"
            :showDetailButton="showDetailButton"
            @clicked-element="clickedElement"
          />
          <v-divider :key="`divider__${index}`" />
        </template>
      </v-virtual-scroll>
    </v-list>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'
import MapMonitorListItem from './MapMonitorListItem.vue'

const utc = require('dayjs/plugin/utc')

export default {
  components: {
    'gm-monitor-list-item': MapMonitorListItem
  },

  props: {
    height: {
      type: String,
      default: () => '200px'
    },

    items: {
      type: Array,
      default: () => []
    },
    showDetailButton: {
      type: Boolean,
      default: true
    },
    expand: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: () => ''
    },
    selectedValues: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      sortValue: 'name_asc',
      search: '',
      componentKey: 0,
      model: [],
      toggleSearch: false,
      toggleOrder: false,
      groupsFilter: -1
    }
  },
  computed: {
    groups () {
      const result = []
      result.push({ id: -1, name: `All Vehicles (${this.items.length})` })
      this.$store.state.groups.list.forEach(el => {
        result.push({
          id: el.id,
          name: `${el.name} (${el.units.length})`
        })
      })
      return result
    },

    itemsSelected () {
      if (this.groupsFilter !== -1) {
        const group = this.$store.state.groups.list.find(el => el.id === this.groupsFilter)
        return this.items.filter(el => group.units.includes(el.id))
      }
      return this.items
    },

    units () {
      let items = this.items.filter((item) => item.telemetry)
      // Filter items by group
      if (this.groupsFilter !== -1) {
        const group = this.$store.state.groups.list.find(el => el.id === this.groupsFilter)
        items = items.filter(el => group.units.includes(el.id))
      }
      // Filter items by search
      if (this.search) {
        items = items.filter(el => {
          return el.name.toLowerCase().includes(this.search.toLowerCase())
        })
      }
      // Sort items by name
      // Ascending
      if (this.sortValue === 'name_asc') {
        items = items.sort((a, b) => {
          if (a.name < b.name) return -1
          if (a.name > b.name) return 1
          return 0
        })
      }
      // Descending
      if (this.sortValue === 'name_desc') {
        items = items.sort((a, b) => {
          if (a.name > b.name) return -1
          if (a.name < b.name) return 1
          return 0
        })
      }
      // Sort items by date
      // Ascending
      if (this.sortValue === 'date_asc') {
        items = items.filter(el => el.telemetry).sort((a, b) => {
          if (a.telemetry?.receivedAt < b.telemetry?.receivedAt) return -1
          if (a.telemetry?.receivedAt > b.telemetry?.receivedAt) return 1
          return 0
        })
      }
      // Descending
      if (this.sortValue === 'date_desc') {
        items = items.filter(el => el.telemetry).sort((a, b) => {
          if (a.telemetry?.receivedAt > b.telemetry?.receivedAt) return -1
          if (a.telemetry?.receivedAt < b.telemetry?.receivedAt) return 1
          return 0
        })
      }
      return items
    },
    listData () {
      return Array(2).fill(
        {
          title: 'Some title',
          subtitle: 'Driver name',
          icon: 'mdi-truck',
          date: Date.now()
        }
      )
    },
    filters () {
      return [
        {
          label: 'Date',
          icon: 'mdi-arrow-up',
          value: 'date_asc'
        },
        {
          label: 'Date',
          icon: 'mdi-arrow-down',
          value: 'date_desc'
        },
        {
          label: 'A - Z',
          icon: 'mdi-alpha-a-box',
          value: 'name_asc'
        },
        {
          label: 'Z - A',
          icon: 'mdi-alpha-z-box',
          value: 'name_desc'
        }
      ]
    },

    groupDialog: {
      get () {
        return this.$store.state.groups.dialog
      },

      set (value) {
        this.$store.commit('groups/toggleDialog', value)
      }
    },

    groupIsEditting: {
      get () {
        return this.$store.state.groups.isEditting
      },

      set (value) {
        this.$store.commit('groups/setIsEditting', value)
      }
    }
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    sortUnits (list, val = 0) {
      if (this.sortValue.includes('date')) {
        return this.sortByDate(list, val)
      } else if (this.sortValue.includes('name')) {
        console.log('Sort by name')
        return this.sortByName(list, val)
      }
    },
    sortByName (list, val = 0) {
      const finalList = list.sort((a, b) => {
        if (a.name < b.name) {
          return -1 + val
        }
        if (a.name > b.name) {
          return 1 - val
        }
        // a debe ser igual b
        return 0
      })
      return finalList
    },
    sortByDate (list, val = 0) {
      list.sort((a, b) => {
        if (!(a.telemetry?.receivedAt && b.telemetry?.receivedAt)) {
          return 0
        }
        if (a.telemetry?.receivedAt < b.telemetry?.receivedAt) {
          return -1 + val
        }
        if (a.telemetry?.receivedAt > b.telemetry?.receivedAt) {
          return 1 - val
        }
        // a debe ser igual b
        return 0
      })
      return list
    },
    parseDate (date) {
      dayjs.extend(utc)
      if (date === null) {
        return dayjs()
      } else {
        return dayjs(date.receivedAt * 1000).format('DD/MM/YYYY HH:mm')
      }
    },
    forceRerender () {
      // console.log('called')
      this.componentKey += 1
    },
    async showDetail (unit) {
      // console.log('unit -> hello')
      this.$emit('show-detail', unit)
    },
    clickedElement (unit) {
      this.$emit('clicked-element', unit)
    },
    viewAllElements () {
      this.$emit('view-all-elements', this.units)
    },
    isItemSelected (unit) {
      return !!this.selectedValues.find(item => item.id.toString() === unit.id.toString())
    },
    toogleExpand () {
      this.$emit('toogle-expand')
    },

    toggleAction (action) {
      switch (action) {
        case 'search':
          this.toggleOrder = false
          if (this.toggleSearch) {
            this.toggleSearch = false
          } else {
            this.toggleSearch = true
          }
          break

        case 'order':
          this.toggleSearch = false
          if (this.toggleOrder) {
            this.toggleOrder = false
          } else {
            this.toggleOrder = true
          }
          break
      }
    },

    toggleAlphabeticOrder () {
      this.sortValue = this.sortValue.includes('desc') ? 'name_asc' : 'name_desc'
    },

    openGroupsDialog () {
      this.groupDialog = true
      this.groupIsEditting = true
    }
  }
}
</script>

<style lang="scss" scoped>

.list--container {
  height: 100%;
  overflow: auto;
}

.unit-list-title {
  font-size: 0.71rem;
}

.striped--divider {
  height: 2px;
  background: linear-gradient(-70deg,transparent 35%,#FFF 45%, #FFF 55%,transparent 65%);
  background-size: 8px 2px;
  border: none;
}
</style>