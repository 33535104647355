<template>
  <v-dialog
    :value="dialog"
    :max-width="width.dialog"
    scrollable
    persistent
    transition="dialog-transition"
  >
    <v-card height="80vh">
      <v-row style="width: 100%; margin: 0">
        <v-col
          cols="12"
          class="pa-0 d-flex flex-column better-scrollbar"
          style="height: 80vh; overflow-y: auto"
        >
          <v-card-title>
            Import Geozones using a file
            <v-spacer />
            <v-btn icon text @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-divider class="mx-4 my-2" />

          <v-card-text class="better-scrollbar pa-6">
            <v-row no-gutters class="">
              <v-col cols="12" class="pa-2">
                <v-file-input
                  :label="$t('trailers.file.upload')"
                  accept=".kml"
                  :error="errors.file.length > 0"
                  :error-messages="errors.file"
                  outlined
                  hide-details
                  @change="convertToBase64"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-spacer />
          <v-card-actions class="d-flex justify-space-between flex-row-reverse">
            <v-btn
              v-if="isEditting"
              color="green darken-2 white--text"
              :disabled="isLoading"
              @click="uploadFile"
            >
              <v-icon>mdi-check-circle</v-icon>
              {{ $t('actions.save') }}
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { orm } from '@/mixins'
import importGeozones from 'GraphQL/mutations/geofences/import.gql'
import { mapState } from 'vuex'
// import { Message } from '@/components/layo'

export default {
  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },

    readonly: {
      type: Boolean,
      default: () => false
    },

    showDialog: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      geozoneFile: null,
      defaultObject: {
        file: ''
      },
      errors: {},
      loading: false
    }
  },

  computed: {
    ...mapState(['width', 'isLoading', 'isDark']),
    user () { return this.$store.state.session },
    isEditting: {
      get () {
        return !this.readonly
      },
      set (value) {
        this.$emit('set-readonly', !value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    title () {
      return this.$i18n.t('groups.title.import')
    }
  },

  watch: {
    // dialog (value) {
    //   if (value) {
    //     window.particlesJS.load('sidebarParticles', '/particles.sidebar.json', () => {
    //       console.log('[ParticlesJS] Loaded')
    //     })
    //   }
    // }
  },

  beforeDestroy () {
    this.resetErrors()
  },

  created () {
    this.resetErrors()
  },

  methods: {
    convertToBase64 (file) {
      if (!file) {
        this.geozoneFile = null
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const result = reader.result
        /**
         * Base64 has ~33% size increase compare to its original
         * decoded size
         *
         * to get the original decoded size we must multiply by
         * ~0.7518796992
         * and we will get the size in bytes of the original file
         */
        const kilobytes = result.length * 0.7518796992 / 1000
        // Max size of ~1.5 MB
        if (kilobytes > 1500) {
          this.$store.commit('toggleSnackbar', {
            message: this.$t('helpers.errors.file_size_too_big'),
            color: 'orange darken-2'
          }, { root: true })
          console.warn('File size too big with kilobytes: ', kilobytes)
          return
        }
        if (
          result.startsWith('data:application/vnd.google-earth.kml') ||
          result.startsWith('data:application/octet-stream')
        ) {
          this.geozoneFile = result
        } else {
          this.$store.commit('toggleSnackbar', {
            message: this.$t('helpers.errors.invalidFormat'),
            color: 'orange darken-2'
          }, { root: true })
        }
      }
    },
    async uploadFile () {
      this.resetErrors()
      this.loading = true
      const variables = {
        apiToken: this.user.apiToken,
        file: this.geozoneFile
      }
      this.$apollo
        .mutate({
          mutation: importGeozones,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(response => {
          const { status, errors, result } = response.data.importGeofencesFile

          switch (status) {
            case 'OK':        
              this.$store.commit('toggleSnackbar', {
                message: this.$i18n.t('geofences.success.import', {
                  count: result
                }),
                color: 'success'
              })
              this.$store.dispatch('geofences/getList', true)
              break
            case 'BADREQUEST':
              this.errors = this.parseErrors(errors)
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
              break
            case 'NOTFOUND':
              this.$store.commit('toggleSnackbar', { message: this.$i18n.t('errors.not_found'), color: 'warning' }, { root: true })
              break
          }
        })
        .catch(_ => {
          this.$store.commit('toggleSnackbar')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
